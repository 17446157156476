import AnimatedText from 'components/AnimatedText';
import { DiffBadge } from 'components/DiffBadge';
import Loader from 'components/Loader';
import QuizProgressIcon from 'components/QuizProgressIcon';
import { motion } from 'framer-motion';
import { ChatMessage } from 'models/chatModels';
import { Playlist } from 'models/videoModels';
import React, { useMemo } from 'react'
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'Routes';
import { useAppStore } from 'stores/appStore';
import { useEduquizStore } from 'stores/eduquizStore';
import { useExpertsStore } from 'stores/expertsStore';
import { useI18nStore } from 'stores/i18nStore';
import { useOrderStore } from 'stores/orderStore';
import { useVideoStore } from 'stores/videoStore';

interface ChatSuggestionElementProps {
    index: number;
    chatId: string;
    message: ChatMessage;
}

function ChatSuggestionElement({ index, chatId, message }: ChatSuggestionElementProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useAppStore(state => state.isMobile);
    const getTranslation = useI18nStore(state => state.getTranslation);
    const argumentList = useEduquizStore(state => state.argumentList);
    const randomValue = useMemo(() => Math.floor(Math.random() * 3) + 1, []);

    const variantChildren = {
        "initial": {
            opacity: 0,
            scale: 0.4,
            y: 100,
        },
        "animate": {
            opacity: 1,
            scale: 1,
            y: 0,
        }
    };
    const slashMotion = {
        "hover": {
            height: "calc(100% + 8px)",
            transition: {
                type: "spring",
                bounce: 0.7,
            }
        }
    };
    if (message.moduleId) {
        const quizArgument = argumentList?.find(argument => argument.argumentId === message.argumentId);
        if (!quizArgument) {
            return <Loader />;
        }
        const quizModule = (quizArgument.argumentDetail.modules ?? []).find(module => module.moduleId === message.moduleId);
        if (!quizModule) {
            return <Loader />;
        }
        const startModule = (module: any) => {
            navigate(routes.eduquizModule(module.moduleId));
        }
        const color = quizModule.moduleDetail.color?.replace('0xFF', '#');
        return (
            <div>
                <strong>
                    <AnimatedText delay={0.1}
                        repeat={0}
                        i18nPrefix="chat:eduquiz_suggestions."
                        strings={[
                            `${randomValue}`,
                        ]} />
                </strong>
                <Card className='my-3' style={{ maxWidth: isMobile ? "100%" : "512px" }}>
                    {quizModule && <Card.Body className='text-center flex-column d-flex align-items-center'>
                        <div className='flex-fill text-center flex-column d-flex align-items-center justify-content-evenly w-100'>
                            <div className='rounded-circle bg-light bg-opacity-100'>
                                <QuizProgressIcon
                                    percentage={quizModule.moduleDetail.userModule!.userModuleDetail.percentageCompletion}
                                    icon={quizModule.moduleDetail.fontIcon ?? null} iconUrl={quizModule.moduleDetail.iconId}
                                    color={color ?? null} width={4} />
                            </div>
                            <h5 className='mt-2 fs-5 fw-bolder text-body-emphasis text-truncate' style={{
                                lineHeight: "1.4rem",
                                width: "calc(100% - 1rem)",
                            }}>{getTranslation(quizModule.moduleDetail.title)}</h5>
                            <DiffBadge diff={quizModule.moduleDetail.difficulty} />
                        </div>
                        <button className="btn btn-primary border-0 bg-primary-subtle text-primary btn-block w-100 rounded-pill mt-3"
                            onClick={() => startModule(quizModule)}>
                            {t(quizModule.moduleDetail.userModule!.userModuleDetail.percentageCompletion !== 0 ? 'common:continue' : 'common:start')}
                        </button>
                    </Card.Body>}
                </Card>
            </div>
        )
    }
    if (message.expertId) {
        if (!useExpertsStore.getState().expertsList) {
            useExpertsStore.getState().initExpertsStore();
            return <Loader />;
        }
        const expert = useExpertsStore.getState().expertsList?.find(expert => expert.expertId === message.expertId);
        if (expert) {
            const freeCallsRemaining = useOrderStore.getState().remainingFreeBookingsForExpert(expert.expertId);
            return (
                <div>
                    <strong>
                        <AnimatedText delay={0.1}
                            repeat={0}

                            i18nPrefix="chat:expert_suggestions."
                            strings={[
                                `${randomValue}`,
                            ]} />
                    </strong>
                    <motion.div

                        id={expert.expertId}
                        key={expert.expertId}
                        variants={variantChildren}
                        style={{ maxWidth: isMobile ? "100%" : "512px" }}
                        className="ExpertCard cursor-pointer my-3 col" onClick={() => navigate(routes.expertDetail(expert.expertId))}>
                        <motion.div
                            variants={variantChildren}
                            whileHover={"hover"} className="card d-flex flex-row bg-primary-subtle h-100">
                            <div
                                className="rounded-start position-relative"
                                style={{
                                    height: isMobile ? "280px" : "280px",
                                    marginTop: isMobile ? "-24px" : "-24px",
                                    /* width: "30%", */
                                    width: isMobile ? "180px" : "200px",
                                    clipPath: 'border-box',
                                }} >
                                <motion.img
                                    variants={slashMotion}
                                    src={expert.expertDetail.img} alt="" className="position-absolute bottom-0 mx-auto px-3" style={{
                                        objectPosition: "bottom",
                                        objectFit: "contain",
                                        height: "100%",
                                        transform: "translateX( -50% )",
                                        left: "50%",
                                        transformOrigin: "bottom",
                                    }} />
                            </div>
                            <div className="card-body d-flex flex-column bg-body rounded-end">
                                <span className={"mb-2 text-uppercase " + ((freeCallsRemaining <= 0) ? "invisible" : "")}>{t('experts:free_call', { count: freeCallsRemaining })}</span>
                                <h5 className="card-title mb-3">{getTranslation(expert.expertDetail.descriptionBrief)}</h5>
                                <div className="nav mt-auto ">
                                    {
                                        expert.expertDetail.category
                                            .map((category, index) => (
                                                <button key={index} className="btn btn-outline-primary border-0 rounded-pill me-2 mb-1 text-truncate"
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        backgroundColor: "var(--bs-primary-subtle)",
                                                        color: "var(--bs-primary)",
                                                    }} value={category}>
                                                    {category}
                                                </button>
                                            ))
                                    }
                                </div>
                                <span className="font-fancy text-end h5 pt-2 mb-0">{expert.expertDetail.name}</span>
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            );
        }
    }
    if (message.playlistId) {
        if (!useVideoStore.getState().playlists) {
            useVideoStore.getState().initVideoStore();
            return <Loader />;
        }
        const playlist = (useVideoStore.getState().playlists ?? []).find(playlist => playlist.playlistId === message.playlistId);
        if (!playlist) {
            return <Loader />;
        }
        const minutesOfPlaylist = (currentPlaylist: Playlist) => currentPlaylist.playlistDetail.videos.reduce((acc, video) => acc + video.videoDetail.minutes, 0);
        return (
            <div>
                <strong>
                    <AnimatedText delay={0.1}
                        repeat={0}
                        i18nPrefix="chat:playlist_suggestions."
                        strings={[
                            `${randomValue}`,
                        ]} />
                </strong>
                <motion.div
                    onClick={() => navigate(routes.playlist(playlist.playlistId))}
                    id={playlist.playlistId}
                    key={playlist.playlistId}
                    variants={variantChildren}
                    style={{ maxWidth: isMobile ? "720px" : "720px" }}
                    className="my-3">
                    <motion.div
                        variants={variantChildren}
                        whileHover={"hover"}
                        className="card card-hover h-100 d-flex flex-row bg-primary-subtle">
                        <div className="position-relative" style={{
                            height: isMobile ? "180px" : "180px",
                            marginTop: isMobile ? "-24px" : "-24px",
                            /* width: "30%", */
                            width: isMobile ? "180px" : "200px",
                            clipPath: 'border-box',
                        }} >
                            <motion.img
                                variants={slashMotion}
                                src={playlist.playlistDetail.thumbnail} alt="playlist.playlistDetail" className="position-absolute bottom-0 mx-auto px-3" style={{
                                    objectPosition: "bottom",
                                    objectFit: "contain",
                                    height: "100%",
                                    transform: "translateX( -50% )",
                                    left: "50%",
                                    transformOrigin: "bottom",
                                }} />
                        </div>
                        <div className="card-body rounded-end d-flex flex-column bg-body">
                            <h5 className="card-title mb-3">{getTranslation(playlist.playlistDetail.title)}</h5>
                            <div className='text-uppercase mt-auto'>
                                {playlist.playlistDetail.videos.length} {t('videos:lessons')} | {minutesOfPlaylist(playlist)} MIN
                            </div>
                            <span className="font-fancy h5 pt-2 mb-0 text-truncate">by {getTranslation(playlist.playlistDetail.metadata)}</span>
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        )
    }
    return (
        <div>

        </div>
    )
}

export default ChatSuggestionElement