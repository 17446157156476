import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import QuizProgressIcon from 'components/QuizProgressIcon';
import { t, use } from 'i18next';
import { QuizModule } from 'models/eduquizModels';
import React, { useEffect, useMemo } from 'react'
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { convertColorToHex } from 'utils/functions';
import finn from "assets/images/finn.png";
import { useI18nStore } from 'stores/i18nStore';
import { useRankingStore } from 'stores/rankingStore';
import { RankingItem, RankingPodiumItem } from 'pages/ranking/RankingPage';
import { DiffBadge } from 'components/DiffBadge';
import { AmplitudeService } from 'services/amplitudeService';
import { quotes } from 'assets/quotes';

import espertoPlaceholder from 'assets/images/expert-placeholder.png';
import { Link } from 'react-router-dom';
import UserDashboardChatStartCard from './UserDashboardChatStartCard';
import Mark from 'components/Mark';
import { useUserStore } from 'stores/userStore';
import TitleViewAll from 'components/TitleViewAll';
import { useVideoStore } from 'stores/videoStore';
import VideoPlayer from 'pages/videos/VideoPlayer';
import { Playlist, Video } from 'models/videoModels';
import { useFeedbackStore } from 'stores/feedbackStore';
import SectionTitle from 'components/SectionTitle';
import { useIsMobile } from 'stores/appStore';

export function ChatWidget() {
    return (
        <Card className='w-100 mt-3'>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div
                        className="ChatMessageElement__avatar rounded-circle bg-light flex-shrink-0 text-primary d-flex justify-content-center align-items-center me-2 p-2"
                        style={{ width: '36px', height: '36px' }}
                    >
                        <img src={finn} className="img-fluid" alt=""></img>
                    </div>
                    <div className="h5 fs-6 mb-0 fw-bolder px-3 bg-transparent rounded-3 me-auto">
                        {t('chat:placeholder')}
                    </div>
                    <CircleButton onClick={() => {
                        //submit form
                        document.getElementById('chatMessageInputWidget')?.closest('form')?.dispatchEvent(new Event('submit'));
                    }}
                        variant="primary" iconClassName="fas fa-paper-plane"></CircleButton>

                </div>
                <UserDashboardChatStartCard title="Dubbi sul cedolino" description="Comprendi la tua busta paga" icon="fa-file-invoice" type="cedolino" setLoading={() => { }} />
                <UserDashboardChatStartCard title="Assistenza sul CCNL" description="Chiarimenti sul tuo CCNL" icon="fa-file-user" type="CCNL" setLoading={() => { }} />
            </Card.Body>
        </Card>);
}
export function MixedRow({ suggestedModule, suggestedExpert }: { suggestedModule: QuizModule | null, suggestedExpert: any }) {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const navigate = useNavigate();
    const startModule = (quizModule: QuizModule) => {
        AmplitudeService.logEvent('Start Suggested Module from Homepage',
            {
                moduleId: quizModule.moduleId,
                title: quizModule.moduleDetail.title.it ?? quizModule.moduleDetail.title.en ?? 'No title',
                argumentId: quizModule.moduleDetail.argumentId,
            });
        navigate('/welfare/eduquiz/module/' + quizModule.moduleId + '/unit/' + quizModule.moduleDetail.unitIds[suggestedModule!
            .moduleDetail
            .userModule!
            .userModuleDetail
            .unitCompleted]);
    }
    return (
        <div className='d-flex'>
            {/* CARD EXPERT */}
            <div className='pt-3 position-relative flex-fill'>
                <Mark background='bg-warning border-warning-subtle' style={{
                    position: "absolute",
                    top: "2rem",
                    right: "1rem",
                    zIndex: 1002,
                }}>
                    {t('user_dashboard:experts')}
                </Mark>
                <Card className='w-100 h-100'>

                    <div
                        className="card-img-top position-relative bg-primary-subtle"
                        style={{
                            height: "calc(130px - 0.5rem)",
                        }}>
                        <img src={suggestedExpert?.expertDetail.img}
                            className="card-img-top" alt=""
                            style={{
                                position: "absolute",
                                bottom: "0rem",
                                height: "145px",
                                objectFit: "contain", objectPosition: "center center",
                                zIndex: 1002,
                            }} />
                    </div>
                    <Card.Body className='flex-column d-flex align-items-start'>
                        <span className='text-muted text-uppercase'
                            style={{
                                fontSize: "0.7rem",
                            }}
                        >
                            {getTranslation(suggestedExpert?.expertDetail.title)}
                        </span>
                        <span className='mt-2 mb-0 fs-5 fw-bolder text-body-emphasis text-decoration-none'

                            style={{
                                lineHeight: "1.4rem",
                            }}
                        >{getTranslation(suggestedExpert?.expertDetail.descriptionBrief)}</span>
                        {/*                             <button className="btn btn-primary btn-block w-100 rounded-pill mt-3"
                    onClick={() => window.open(getTranslation(suggestedExpert?.expertDetail.url), '_blank')}>
                    {t('user_dashboard:book_a_session')}
                </button> */}
                        <h5 className='mt-3 mb-0 fs-5 text-primary font-fancy '>by {suggestedExpert?.expertDetail.name}</h5>
                        <button className="btn btn-primary btn-block w-100 rounded-pill mt-3"
                            onClick={() => navigate('/welfare/experts/' + suggestedExpert?.expertId)}>
                            {t('common:discover')}
                        </button>
                    </Card.Body>
                </Card>
            </div>
            <div className='pe-3'></div>
            {/* CARD LEARN */}
            <div className='pt-3 flex-fill'>
                <Card className='h-100'>
                    <Mark background='bg-warning border-warning-subtle' style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        zIndex: 1002,
                    }}>
                        {t('user_dashboard:learn')}
                    </Mark>
                    {suggestedModule && <Card.Body className='text-center flex-column d-flex align-items-center'>
                        <div className='flex-fill text-center flex-column d-flex align-items-center justify-content-evenly'>
                            <div className='rounded-circle bg-light bg-opacity-100'>
                                <QuizProgressIcon
                                    percentage={suggestedModule.moduleDetail.userModule!.userModuleDetail.percentageCompletion}
                                    icon={suggestedModule.moduleDetail.fontIcon ?? null} iconUrl={suggestedModule.moduleDetail.iconId}
                                    color={convertColorToHex(suggestedModule.moduleDetail.color!)} width={4} />
                            </div>
                            <h5 className='mt-2 fs-5 fw-bolder text-body-emphasis' style={{
                                lineHeight: "1.4rem",
                            }}>{getTranslation(suggestedModule.moduleDetail.title)}</h5>
                            <DiffBadge diff={suggestedModule.moduleDetail.difficulty} />
                        </div>
                        <button className="btn btn-primary btn-block w-100 rounded-pill mt-3"
                            onClick={() => startModule(suggestedModule)}>
                            {t(suggestedModule.moduleDetail.userModule!.userModuleDetail.percentageCompletion !== 0 ? 'common:continue' : 'common:start')}
                        </button>
                    </Card.Body>}
                </Card>
            </div>
        </div>);
}
export function VideoWidget({ className, viewAll, playlistId }: { className?: string, viewAll?: boolean, playlistId?: string }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const playlists = useVideoStore(state => state.playlists);
    const [currentVideo, setCurrentVideo] = React.useState<Video | null>(null);
    const [currentPlaylist, setCurrentPlaylist] = React.useState<Playlist | null>(null);
    const [showModal, setShowModal] = React.useState(false);
    useEffect(() => {
        if (playlists === null) {
            useVideoStore.getState().initVideoStore();
        }
    }, [playlists]);
    useEffect(() => {
        if (currentVideo === null && playlists !== null) {
            if (playlistId) {
                const playlist = playlists.find(p => p.playlistId === playlistId);
                if (playlist) {
                    setCurrentPlaylist(playlist!);
                    setCurrentVideo(playlist!.playlistDetail.videos[0]);
                    return;
                }
            }
            setCurrentPlaylist(playlists[0]);
            setCurrentVideo(playlists[0].playlistDetail.videos[0]);

        }
    }, [currentVideo, playlists, playlistId]);

    const playlistHistory =
        currentPlaylist ? useVideoStore.getState().getPlaylistHistory(currentPlaylist.playlistId) : null;

    const videoHistory = currentVideo ? useVideoStore.getState().getVideoHistory(currentVideo.videoId) : null;

    if (!playlists) {
        return <Loader />;
    }

    if (!currentPlaylist) {
        return <></>;
    }

    const minutesOfPlaylist = currentPlaylist.playlistDetail.videos.reduce((acc, video) => acc + video.videoDetail.minutes, 0);
    return (<div className={(className ?? "pt-3") + " position-relative h-100"}>
        <Mark background='bg-warning border-warning-subtle' style={{
            position: "absolute",
            top: "2rem",
            right: "1rem",
            zIndex: 1002,
        }}>
            {t('user_dashboard:video_course')}
        </Mark>
        <Card className='bg-primary border-0 h-100' style={{
            width: "100%",
        }}
        >
            <Card.Body className='text-start flex-column d-flex align-items-start justify-content-end'>
                <h5 className='fs-5 mb-2 text-white fw-bolder' style={{
                    lineHeight: "1.4rem",
                }}>

                    {getTranslation(currentPlaylist.playlistDetail.title)}
                </h5>
                <span className='text-white text-uppercase small'

                >
                    {currentPlaylist.playlistDetail.videos.length} {t('videos:lessons')} | {minutesOfPlaylist} MIN
                </span>
                <h5 className='mt-1 mb-0 fs-5 text-white font-fancy mb-auto'>by {currentPlaylist.playlistDetail.metadata?.it}
                </h5>
                <div className='d-flex flex-column justify-content-center'>
                    {currentVideo && <VideoPlayer video={currentVideo!}
                        onVideoPlay={() => {
                            console.log('video has started playing');
                            if (videoHistory) {
                                videoHistory.watching = true;
                                useVideoStore.getState().updateVideoHistory(videoHistory);
                            }
                            if (playlistHistory) {
                                playlistHistory.watching = true;
                                useVideoStore.getState().updatePlaylistHistory(playlistHistory);
                            }
                        }}
                        onVideoIsPlaying={async (data) => {
                            console.log('video is playing');
                            if (videoHistory) {
                                videoHistory.watching = true;
                                videoHistory.percentage = data.percentage;
                                videoHistory.seconds = Math.floor(data.seconds);
                                await useVideoStore.getState().updateVideoHistory(videoHistory);
                            }
                            if (playlistHistory) {
                                playlistHistory.watching = true;
                                await useVideoStore.getState().updatePlaylistHistory(playlistHistory);
                            }
                        }}
                        showPlayer={showModal}
                        closePlayer={() => setShowModal(false)}
                        author={currentPlaylist.playlistDetail.metadata?.it}
                        onNextVideoRequested={() => {
                            //navigate to the next video
                            const index = currentPlaylist.playlistDetail.videos.findIndex(v => currentVideo?.videoId === v.videoId);
                            if (index < currentPlaylist.playlistDetail.videos.length - 1) {
                                setCurrentVideo(currentPlaylist.playlistDetail.videos[index + 1]);
                            } else {
                                setCurrentVideo(currentPlaylist.playlistDetail.videos[0]);
                                setShowModal(false);
                            }
                        }}
                        onVideoEnd={(videoId) => {
                            if (videoHistory) {
                                videoHistory.alreadyCompleted = true;
                                videoHistory.watching = false;
                                videoHistory.seconds = 0;
                                videoHistory.percentage = 1;
                                useVideoStore.getState().updateVideoHistory(videoHistory);
                            }
                            if (playlistHistory) {
                                if (playlistHistory.videoIds.indexOf(videoId) === -1) {
                                    playlistHistory.videoIds.push(videoId);
                                }
                                if (playlistHistory.videoIds.length === currentPlaylist.playlistDetail.videos.length) {
                                    playlistHistory.alreadyCompleted = true;
                                }
                                console.log('playlistHistory', playlistHistory);
                                useVideoStore.getState().updatePlaylistHistory(playlistHistory);
                            }
                            //navigate to the next video
                            const index = currentPlaylist.playlistDetail.videos.findIndex(video => video.videoId === videoId);
                            if (index < currentPlaylist.playlistDetail.videos.length - 1) {
                                setCurrentVideo(currentPlaylist.playlistDetail.videos[index + 1]);
                            } else {
                                useFeedbackStore.getState().checkCourseFeedback(currentPlaylist.playlistId, currentPlaylist.playlistDetail.title.it);
                                setCurrentVideo(currentPlaylist.playlistDetail.videos[0]);
                                setShowModal(false);
                            }
                        }}
                    >
                        <button className="btn btn-light text-primary rounded-pill mt-3 w-100 "
                            onClick={() => { setShowModal(true); AmplitudeService.logEvent('Start Video from Homepage', { videoId: currentVideo?.videoId, title: currentVideo?.videoDetail.title }) }}>
                            {t('common:play')}
                        </button>
                    </VideoPlayer>}
                    <button className="btn btn-link text-white bg-primary rounded-pill mt-1"
                        onClick={() => navigate('/welfare/playlist/' + currentPlaylist.playlistId)}>
                        {t('user_dashboard:all_lessons')}
                    </button>
                </div>
            </Card.Body>
            <img src={currentPlaylist?.playlistDetail.thumbnail ?? espertoPlaceholder} className="position-absolute" alt=""
                style={{
                    height: "108%",
                    objectFit: "contain", objectPosition: "bottom center",
                    zIndex: 1002,
                    bottom: 0,
                    right: "0rem",
                    maxWidth: "55%"
                }} />
        </Card>
    </div >
    );
}

export function RankingRow() {
    const rankingList = useRankingStore(state => state.rankingList);
    useEffect(() => {
        useRankingStore.getState().initRanking();
    }, []);
    return (
        <>
            {/* <SectionTitle title={t('user_dashboard:ranking')} /> */}
            <div className='card w-100 position-relative'>
                <div className='card-body pe-3  d-flex flex-column justify-content-start'>
                    <div className='row gy-3 gx-0'>
                        {/*                         <div className='col-12 col-md-12 mb-3'>
                            <div className='container-fluid p-0 '>
                                <div className="row align-items-end gx-3">
                                    <RankingPodiumItem rankingList={rankingList} ranking={1} />
                                    <RankingPodiumItem rankingList={rankingList} ranking={0} />
                                    <RankingPodiumItem rankingList={rankingList} ranking={2} />
                                </div>
                            </div>
                        </div> */}
                        {rankingList != null && rankingList
                            .filter((ranking: any, index: number) => index < 3 || ranking.userProfileId === useUserStore.getState().user?.userProfileId)
                            .map((ranking: any, index: number) => <RankingItem key={index} ranking={index + 1} data={ranking} />)}

                    </div>
                    {!rankingList && <Loader />
                    }
                </div>
            </div></>);
}

export function QuoteRow() {
    const getTranslation = useI18nStore(state => state.getTranslation);
    //get a random quote
    const randomQuote = useMemo(() => {
        quotes.sort(() => Math.random() - 0.5);
        return quotes[0];
    }, []);
    return (
        <Card className='card bg-body text-muted mt-3'>
            <Card.Body className='p-4'>
                <i className='fas fa-quote-left fa-3x text-primary'></i>
                <h3 className='text-center mt-2 '>
                    {getTranslation(randomQuote.quote)}
                </h3>
                <i className='fas fa-quote-right text-end fa-3x w-100 text-primary ms-auto'></i>
                <span className='text-end font-italic w-100 d-inline-block font-fancy fs-5' style={{ fontStyle: "italic" }}>
                    - {randomQuote.author}
                </span>
            </Card.Body>
        </Card>
    );
}