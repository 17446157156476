import React, { forwardRef } from 'react'
import { useExperiencePointsStore } from "stores/experiencePointsStore";
import XPSymbol from "./XPSymbol";

const XpCounter = forwardRef<HTMLDivElement>((props, ref) => {
  const currentExperiencePointData = useExperiencePointsStore(state => state.currentExperiencePointData);
  // init xp if currentExperiencePointData is null
  React.useEffect(() => {
    if (currentExperiencePointData === null) {
      useExperiencePointsStore.getState().getUserExperiencePoints();
    }
  }, [currentExperiencePointData]);

  return (
    <div ref={ref} className="XpCounter mx-2 d-flex">
      <XPSymbol />
      <h5 className="mb-0">{currentExperiencePointData?.experiencePointDetail.currentExperiencePoints ?? "   "}</h5>
    </div>
  )
})

export default XpCounter