import userPlaceholder from 'assets/images/userph.jpg';
import HeaderAdmin from 'components/HeaderAdmin';
import Spinner from 'components/Spinner';
import { use } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { routes } from 'Routes';
import { useCompanyManagerStore } from 'stores/companyManagerStore';
import { useCompanyStore } from 'stores/companyStore';
import { useEduquizManagerStore } from 'stores/eduquizManagerStore';
import { useI18nStore } from 'stores/i18nStore';
import { useShopManagerStore } from 'stores/shopManagerStore';
import { useUsersStore } from 'stores/usersStore';
import { useVideoStore } from 'stores/videoStore';
import { CountUp } from 'use-count-up';
import Config, { appThemes } from 'utils/config';
import { generateImageUrl, useTitle } from 'utils/functions';


export default function DashboardPage() {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const usersVideos = useVideoStore(state => state.usersVideos);
    const companyList = useCompanyManagerStore(state => state.companyList);
    useEffect(() => {
        if (!companyList) {
            useCompanyManagerStore.getState().init();
        }
    }, [companyList]);

    useEffect(() => {
        useVideoStore.getState().getAllUserVideos();
    }, []);

    // Get the list of users and sort them by registration date
    const usersList = useUsersStore(state => state.usersList?.sort((a, b) => (a.userProfileDetail.registrationDate > b.userProfileDetail.registrationDate) ? -1 : 1));
    const initUsers = useUsersStore((state) => state.initUsers);

    const [startingDate, setStartingDate] = React.useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    const [endingDate, setEndingDate] = React.useState(new Date());

    useTitle(t('dashboard:title'));

    // This effect is used to initialize the users list
    useEffect(() => {
        if (!usersList) {
            initUsers();
        } else {
        }
    }, [usersList, initUsers]);


    // Get the list of shop items
    const shopItems = useShopManagerStore((state) => state.shopItems);
    const initShopManager = useShopManagerStore((state) => state.initShopManager);
    const requestUserShopItems = useShopManagerStore((state) => state.requestUserShopItems);
    const userShopItems = useShopManagerStore((state) => state.userShopItems);

    // This effect is used to initialize the shopItems list
    useEffect(() => {
        if (shopItems === null) {
            initShopManager();
        }
    }, [shopItems, initShopManager]);

    // This effect is used to initialize the userShopItems list
    useEffect(() => {
        if (userShopItems === null) {
            requestUserShopItems();
        }
    }, [userShopItems, requestUserShopItems]);
    // match shopItems with userShopItems, by listing the count of each userShopitem per shopItem. the count increase when userShopItem.userShopItemDetail.shopItemId === shopItem.shopItemId
    const shopItemsWithCount = (shopItems ?? []).map((shopItem: any) => {
        const count = (userShopItems ?? []).filter((userShopItem: any) => userShopItem.userShopItemDetail.shopItemId === shopItem.shopItemId).length;
        return { ...shopItem, count, name: shopItem.shopItemDetail.company };
    });
    // sort by count desc
    shopItemsWithCount?.sort((a: any, b: any) => (a.count > b.count) ? -1 : 1);
    // add fill color
    shopItemsWithCount?.forEach((shopItem: any, index: any) => {
        shopItem.fill = Config.chartColors[index];
    });

    // Calculate acccesses per day
    const userData = usersList?.map((user) => { return { userId: user.userProfileId, ...user.userProfileDetail } });
    let accessData;
    let days = [];;
    let weekTotalAccesses;
    if (userData) {
        // Count the number of accesses per day
        const accessCounts: { [date: string]: number } = userData
            ?.flatMap((user) => user.accesses)
            .reduce((counts: { [date: string]: number }, access) => {
                const date = (access ?? []).slice(0, 10) ?? "";
                counts[date as string] = (counts[date as string] || 0) + 1;
                return counts;
            }, {});

        // Convert the access counts to an array of objects
        accessData = Object.entries(accessCounts).map(([date, count]) => ({
            date: date/* .slice(5) */, // update date to mm-dd format
            count,
        }));

        // Order the accessData by date, with the newest first
        accessData.sort((a, b) => (a.date > b.date) ? 1 : -1);

        // Get the names of the days of the week
        const today = new Date();
        const weekDaysNames = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const dayName = date.toLocaleDateString(undefined, { weekday: 'short' });
            weekDaysNames.push(dayName.charAt(0).toUpperCase() + dayName.slice(1));
        }

        // Calculate the number of accesses between startingDate and endingDate
        let currentDate = new Date(startingDate);
        while (currentDate <= endingDate) {
            const count = accessData?.find((data) => {
                const dataDate = new Date(data.date);
                return dataDate.toLocaleDateString() === currentDate.toLocaleDateString();
            })?.count || 0;
            days.push({
                dayName: currentDate.toLocaleDateString().split('/')[0]
                    + '/' + currentDate.toLocaleDateString().split('/')[1]
                , count
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Calculate the total number of accesses for the week
        weekTotalAccesses = days.reduce((total, day) => total + day.count, 0);
    }

    // Prepare data for the user languages pie chart
    // user language has to be counted from usersList.map(user => user.userProfileDetail.language)
    // the data should be an array of objects with name and value keys
    /* like this: const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
  { name: 'Group E', value: 278 },
  { name: 'Group F', value: 189 },
]; */
    const userLangCounts = useMemo(() => {
        const userLangData = usersList?.map(user => user.userProfileDetail.locale) ?? [];
        const counts: { name: string, value: number, label: string }[] = [];
        // count the number of each language, then push it to counts
        userLangData.forEach((lang) => {
            const index = counts.findIndex((item) => item.name === lang);
            if (index === -1) {
                counts.push({ name: lang, value: 1, label: lang });
            } else {
                counts[index].value += 1;
            }
        });
        return counts;
    }, [usersList]);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel: React.FC<{
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        percent: number;
        index: number;
        entry: any;
    }> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        // find lang name using indexx 
        let langName = userLangCounts[index].label;
        if (!langName) {
            langName = 'Not set';
        } else {
            langName = langName.toUpperCase();
        }
        return (
            <>
                <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle">
                    {langName}
                </text>
                <text x={x} y={y + 20} fill="white" textAnchor="middle" dominantBaseline="middle">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            </>
        );
    };
    const userVideoTotalMinutes = useMemo(() => {
        const totalSeconds = usersVideos?.reduce((total, userVideo) => {
            return total + (userVideo.userVideoDetail.totalWatchTime ?? 0);
        }, 0);
        return Math.floor(totalSeconds / 60);
    }, [usersVideos]);

    const totalVideoCompleted = useMemo(() => {
        return usersVideos?.reduce((total, userVideo) => {
            return total + userVideo.userVideoDetail.videoHistories.reduce((total, videoHistory) => {
                return total + (videoHistory.alreadyCompleted ? 1 : 0);
            }, 0);
        }, 0);
    }, [usersVideos]);

    const totalVideoWatched = useMemo(() => {
        return usersVideos?.reduce((total, userVideo) => {
            // return the lenght of videoHistories array
            return total + (userVideo.userVideoDetail.videoHistories.length);
        }, 0);
    }, [usersVideos]);

    const totalPlaylistCompleted = useMemo(() => {
        return usersVideos?.reduce((total, userVideo) => {
            return total + (userVideo.userVideoDetail.playlistHistories.reduce((total, playlistHistory) => {
                return total + (playlistHistory.alreadyCompleted ? 1 : 0);
            }, 0));
        }, 0);
    }, [usersVideos]);

    return (<section className='DashboardPage d-flex flex-column px-1'>
        <HeaderAdmin title='dashboard:title' />
        <div className='row gx-3 gy-3 pb-3'>

            <DashboardPageCard cols="3" >
                <h4 className='text-pre'>{t('dashboard:companies_created')}</h4>
                <h2 className='mb-0'>{companyList !== undefined ? <CountUp isCounting end={companyList?.length} />
                    : <Spinner />}</h2>
            </DashboardPageCard>
            <DashboardPageCard cols="3">
                <h4 className='text-pre'>{t('dashboard:shop_items')}<br />{t('dashboard:sold')}</h4>
                <h2 className='mb-0'>{userShopItems?.length !== undefined ? <CountUp isCounting end={userShopItems?.length} />
                    : <Spinner />}</h2>
            </DashboardPageCard>
            <DashboardPageCard cols="3">
                <h4 className='text-pre'>{t('common:minutes')}<br />{t('dashboard:watched')}</h4>
                <h2 className='mb-0'>{userVideoTotalMinutes !== undefined ? <CountUp isCounting end={userVideoTotalMinutes} />
                    : <Spinner />}</h2>
            </DashboardPageCard>
            <DashboardPageCard cols="3" >
                <h4 className='text-pre'>Playlists<br />{t('dashboard:completedF')}</h4>
                <h2 className='mb-0'>{totalPlaylistCompleted !== undefined ? <CountUp isCounting end={totalPlaylistCompleted} />
                    : <Spinner />}</h2>
            </DashboardPageCard>

            {/* USERS */}
            <DashboardPageCard cols="6">
                <div className="card-title">
                    <h4>{t('dashboard:accesses')}</h4>
                    <h2 className='mb-0'><CountUp isCounting={weekTotalAccesses !== undefined} end={weekTotalAccesses} /></h2>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <div className="form-group me-3">
                        <label htmlFor="startDate">{t('common:from')}:</label>
                        <input type="date" id="startDate" className='form-control' value={startingDate.toISOString().split('T')[0]} onChange={
                            (e) => setStartingDate(new Date(e.target.value))
                        } />
                    </div>
                    <div className="form-group">
                        <label htmlFor="endDate">{t('common:to')}:</label>
                        <input type="date" id="endDate" className='form-control' value={endingDate.toISOString().split('T')[0]} onChange={
                            (e) => setEndingDate(new Date(e.target.value))
                        } />
                    </div>
                </div>
                {days && <ResponsiveContainer width={"100%"} height={420}>
                    <LineChart data={days} margin={{ top: 2, right: 8, bottom: 0, left: 8 }}>
                        <Line type="monotone" dataKey="count" stroke="var(--bs-primary)" strokeWidth={3} animationBegin={0} />
                        <XAxis dataKey="dayName" height={28} tick={{ stroke: "var(--bs-primary)", color: "var(--bs-primary)", }} axisLine={false} tickMargin={8} />
                        <YAxis dataKey="count" width={32} axisLine={false} stroke="var(--bs-gray)" />
                        <Tooltip content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className="card">
                                        <div className="card-body">{`${t('dashboard:accesses')} ${label}: ${payload[0].value}`}</div>
                                    </div>
                                );
                            }
                            return null;
                        }} />
                        <CartesianGrid stroke="var(--bs-gray)" strokeDasharray="10 10" />
                    </LineChart>
                </ResponsiveContainer>}
                {!days && <div className="my-5 w-100 d-flex align-items-center justify-content-center"><Spinner /></div>}
            </DashboardPageCard>

            <DashboardPageCard cols="3">
                <div className="card-title">
                    <h4>{t('dashboard:users')}</h4>
                    <h2><CountUp isCounting={usersList != null} end={usersList?.length} /></h2>
                </div>
                {usersList?.slice(0, 6).map(user => (
                    <div className="user-tile d-flex" key={user.userProfileId + "-user"}>
                        <img className='rounded-circle me-3' src={generateImageUrl(user.userProfileDetail.avatar ?? "") ?? userPlaceholder} height={48} width={48} alt={`${user.userProfileDetail.name} ${user.userProfileDetail.surname}`} />
                        <div className="user-info mw-0">
                            <h6 className="text-truncate mb-1">{`${user.userProfileDetail.name ? user.userProfileDetail.name + " " : ""}${user.userProfileDetail.surname ? user.userProfileDetail.surname : ""}`}</h6>
                            <p className="small text-truncate text-body-tertiary">{user.userProfileDetail.email}</p>
                        </div>
                    </div>
                ))}
                {!usersList && <div className="my-5 w-100 d-flex align-items-center justify-content-center"><Spinner /></div>}
                {/* view all users button */}
                <button className="btn btn-primary btn-block w-100 btn-sm mt-auto" onClick={() => navigate(routes.users())}>{t('common:view_all')}</button>
            </DashboardPageCard>
            {Config.enabledFeatures.shop && <DashboardPageCard cols="3">
                <div className="card-title">
                    <h4>{t('dashboard:shop_items')}</h4>
                    <h2>{<CountUp isCounting={shopItems?.length != null} end={shopItems?.length} />}</h2>
                </div>
                <div className="d-flex flex-column">
                    {shopItems?.slice(0, 6).map((item: any) => (
                        <div className="d-flex align-items-center" key={item.shopItemId + "-sopitem"}>
                            <img className='me-3' src={item.shopItemDetail.logoId ?? userPlaceholder} height={48} width={48} alt={item.name} style={{ objectFit: "cover" }} />
                            <div className="user-info mw-0">
                                <h6 className="text-truncate">{item.shopItemDetail.company}</h6>
                                <p className="text-truncate">{getTranslation(item.shopItemDetail.title)}</p>
                            </div>
                        </div>
                    ))}
                    {!shopItems && <div className="my-5 w-100 d-flex align-items-center justify-content-center"><Spinner /></div>}
                </div>
                {/* view all shop items button */}
                <button className="btn btn-primary btn-block w-100 btn-sm mt-auto" onClick={() => navigate(routes.shopManager())}>{t('common:view_all')}</button>

            </DashboardPageCard>}
            <DashboardPageCard cols="12">
                <div className="card-title">
                    <h4>{t('common:top')} {t('dashboard:shop_items')}</h4>
                </div>
                <div className="d-flex flex-column ">
                    {shopItemsWithCount && <ResponsiveContainer width={"100%"} height={420}>
                        <BarChart data={shopItemsWithCount.slice(0, 6)} layout='horizontal' >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tickMargin={8} />
                            <YAxis allowDecimals={false} width={32} />
                            <Tooltip content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div className="card">
                                            <div className="card-body">{`${JSON.stringify(payload[0].payload.shopItemDetail.company)}: ${payload[0].value}`}</div>
                                        </div>
                                    );
                                }
                                return null;
                            }} />
                            <Bar dataKey="count" fill="var(--bs-primary)" />
                        </BarChart></ResponsiveContainer>
                    }
                    <div className='d-flex w-100 flex-column mt-3'>
                        {shopItemsWithCount?.slice(0, 6).map((item: any) => (
                            <div className='d-flex w-100 flex-row' key={item.shopItemId + "-shopwithcount"}>
                                <div className="d-flex align-items-center">
                                    <img className='me-3' src={item.shopItemDetail.logoId ?? userPlaceholder} height={48} width={48} alt={item.name} style={{ objectFit: "cover" }} />
                                    <div className="user-info mw-0">
                                        <h6 className="text-truncate">{item.shopItemDetail.company}</h6>
                                    </div>
                                </div>
                                <div className='ms-auto'>
                                    <h3>
                                        <CountUp isCounting={item.count} end={item.count} />
                                    </h3>
                                </div>
                            </div>
                        ))}
                    </div>

                    {!shopItemsWithCount && <div className="my-5 w-100 d-flex align-items-center justify-content-center"><Spinner /></div>}
                </div>
            </DashboardPageCard>
            <DashboardPageCard cols="12">
                <div className="card-title">
                    <h4>{t('dashboard:languages')}</h4>
                </div>
                {usersList && <ResponsiveContainer width={"100%"} height={420}>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={userLangCounts}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={140}
                            dataKey="value"

                        >
                            {userLangCounts.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={Config.chartColors[index % Config.chartColors.length]} />
                            ))}
                        </Pie>
                    </PieChart></ResponsiveContainer>
                }
            </DashboardPageCard>
        </div>
    </section >
    )
}
function DashboardPageCard(props: { cols: string, children: React.ReactNode }) {
    return (
        <div className={'col-12 col-lg-' + props.cols}>
            <div className='card  d-flex align-items-stretch h-100'>
                <div className='card-body d-flex flex-column'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}