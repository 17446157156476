import React, { useEffect } from 'react'
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { AuthStates, useAuthStore } from 'stores/authStore';
import Config from 'utils/config';
import RegisterForm from '../register/RegisterForm';
import ReactInputVerificationCode from 'react-input-verification-code';
import ResetPasswordDialog from '../reset_password/ResetPasswordDialog';
import FunnifinLogo from 'components/FunnifinLogo';
import ForgotPasswordDialog from '../forgot_password/ForgotPasswordDialog';

export const LOGIN_BUTTON = {
    "data-bs-toggle": "modal",
    "data-bs-target": "#loginModal"
};
function LoginComponents({ useModal = true }) {
    const authState = useAuthStore((state) => state.authenticationState);
    const doLogin = useAuthStore((state) => state.login);
    const resendEmail = useAuthStore((state) => state.resendEmail);
    const confirmAccount = useAuthStore((state) => state.confirmAccount);
    const [showLogin, setShowLogin] = React.useState(true);
    const [showCode, setShowCode] = React.useState(false);
    const [code, setCode] = React.useState('');
    const [usedEmail, setUsedEmail] = React.useState('');
    const [usedPassword, setUsedPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [doingLogin, setDoingLogin] = React.useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        if (doingLogin) return;
        setDoingLogin(true);
        // get elements from the form
        const { email, password } = e.target.elements;
        setUsedEmail(email.value.trim());
        localStorage.setItem('username', email.value.trim());
        setUsedPassword(password.value.trim());
        toast.promise(doLogin(email.value.trim(), password.value.trim()), {
            loading: t('home:login_in_progress'),
            success: t('home:login_success'),
            error: (err) => {
                if (err.message === 'reset_password_required') {
                    try {
                        document.getElementById('passwordLogin').value = '';
                    } catch (e) {
                        console.log('Error while trying to clear password field');
                    }
                }
                setDoingLogin(false);
                return <>{t("errors:" + err.message)}</>
            },
        }).then(() => {
            setDoingLogin(false);
        }).catch((err) => {
            console.log('Error while trying to login', err);
            setDoingLogin(false);
        });
    };
    const { t } = useTranslation();
    useEffect(() => {
        if (authState === AuthStates.Unconfirmed) {
            setShowCode(true);
        }
    }, [authState]);
    if (authState === AuthStates.Authenticated) {
        return <Navigate to={'/dashboard'} />;
    }
    //on history change, remove .modal-backdrop in document
    window.onpopstate = () => {
        document.querySelector('.modal-backdrop')?.remove();
    }
    const onConfirmationSubmit = (e) => {
        e.preventDefault();

        toast.promise(confirmAccount(usedEmail, code), {
            loading: t('home:confirm_in_progress'),
            success: t('home:confirm_success'),
            error: (err) => <>{t("errors:" + err.message)}</>,
        }).then((result) => {

            toast.promise(doLogin(usedEmail, usedPassword), {
                loading: t('home:login_in_progress'),
                success: t('home:login_success'),
                error: (err) => <>{t("errors:" + err.message)}</>,
            }).catch((err) => {
                console.log('Error while trying to login, during confirmation', err);
            });
        });
    }
    return (
        <>
            <ResetPasswordDialog />
            {useModal && <section className=''>
                <div className="modal fade " id={"loginModal"} aria-labelledby="loginModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content ">
                            <div className="modal-body p-5">

                                {showLogin && <form onSubmit={onSubmit} className='text-center' name="login">


                                    {Config.alternateLoginImage != null ? <img src={Config.alternateLoginImage} alt="" className="img-fluid ms-lg-auto d-block w-50 mx-auto mb-3" />
                                        : <FunnifinLogo className="img-fluid ms-lg-auto w-50 mb-3" alt="" />}
                                    <h3 className='mb-4 h1'>{t('home:login')} </h3>
                                    <p className='d-none'>{t('home:email_needed_message', { companyName: Config.defaultCompanyName })}</p>
                                    <div className="mb-4 form-floating">
                                        <input placeholder="E-mail" type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" required />
                                        <label htmlFor="email" className="form-label">{t('forms:email')}</label>
                                    </div>
                                    <div className="mb-4 form-floating">
                                        <input placeholder="Password" name="password" type={showPassword ? "text" : "password"} className="form-control" id="passwordLogin" required />
                                        <label htmlFor="passwordLogin" className="form-label">{t('forms:password')}</label>
                                        <div className="input-group-append position-absolute end-0 top-0 pt-2 me-2">
                                            <button className="btn btn-secondary p-2 border-0 bg-transparent" type="button" onClick={() => setShowPassword(!showPassword)}>
                                                <i className={`fas fa-eye${showPassword ? "-slash" : ""}`}></i>
                                            </button>
                                        </div>
                                    </div>
                                    {/*                                 <div className="mb-4 form-check">
                                    <input name="rememberMe" type="checkbox" className="form-check-input" id="exampleCheck1"  />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                                </div> */}
                                    {/*    <div className='card-footer d-flex flex-row-reverse'>
                                    </div> */}
                                    <button type="submit" className=" btn btn-primary btn-block mb-4 w-100 mb-4" data-bs-dismiss="modal" >Login</button>
                                    {Config.enabledFeatures.selfRegistration && <div className='text-center d-flex align-items-center justify-content-center'>
                                        <span className='d-block'>{t('home:dont_have_account')}</span>
                                        <span className='text-primary ms-2 text-decoration-underline fw-bold cursor-pointer' disabled={doingLogin} onClick={() => {
                                            setShowLogin(false);
                                        }}> <u>{t('home:register')}</u></span>
                                    </div>}
                                    <ForgotPasswordDialog />
                                </form>}
                                {!showLogin && <RegisterForm setShowLogin={setShowLogin} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
            {!useModal && <form onSubmit={onSubmit} className='text-center' name="login">

                {Config.alternateLoginImage != null ? <img src={Config.alternateLoginImage} alt="" className="img-fluid ms-lg-auto d-block w-50 mx-auto mb-3 d-block" />
                    : <FunnifinLogo className="img-fluid mx-auto w-50 mb-3" alt="" />}
                <h3 className='mb-2 h3'>{t('home:login')} </h3>
                <p className='mb-4'>{t('home:login_subtitle')} </p>

                <p className='d-none'>{t('home:email_needed_message', { companyName: Config.defaultCompanyName })}</p>
                <div className="mb-4 form-floating">
                    <input placeholder="E-mail" type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" required />
                    <label htmlFor="email" className="form-label">{t('forms:email')}</label>
                </div>
                <div className="mb-4 form-floating">
                    <input placeholder="Password" name="password" type={showPassword ? "text" : "password"} className="form-control" id="passwordLogin" required />
                    <label htmlFor="passwordLogin" className="form-label">{t('forms:password')}</label>
                    <div className="input-group-append position-absolute end-0 top-0 pt-2 me-2">
                        <button className="btn btn-secondary p-2 border-0 bg-transparent" type="button" onClick={() => setShowPassword(!showPassword)}>
                            <i className={`fas fa-eye${showPassword ? "-slash" : ""}`}></i>
                        </button>
                    </div>
                </div>
                {/*                                 <div className="mb-4 form-check">
<input name="rememberMe" type="checkbox" className="form-check-input" id="exampleCheck1"  />
<label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
</div> */}
                {/*    <div className='card-footer d-flex flex-row-reverse'>
</div> */}
                <button type="submit" className=" btn btn-primary btn-block mb-4 w-100 mb-4" data-bs-dismiss="modal" >Login</button>
                {Config.enabledFeatures.selfRegistration && <div className='text-center d-flex align-items-center justify-content-center'>
                    <span className='d-block'>{t('home:dont_have_account')}</span>
                    <span className='text-primary ms-2 text-decoration-underline fw-bold cursor-pointer' onClick={() => {
                        setShowLogin(false);
                    }}> <u>{t('home:register')}</u></span>
                </div>}
            </form>}
            {showCode &&
                <div className="modal show d-block bg-opacity-50 bg-black" id={"confirmModal"} aria-labelledby="loginModal" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content ">
                            <div className="modal-body p-5">
                                <form className='d-flex flex-column' onSubmit={onConfirmationSubmit} key={'registerCode'}>

                                    <h3 className='mb-4 h1'>{t('home:register')}</h3>
                                    <p><Trans
                                        i18nKey="home:email_sent_message" // optional -> fallbacks to defaults if not provided
                                        values={{ usedEmail: usedEmail }}
                                        components={{ span: <span className="text-danger fw-bold" /> }}
                                    /></p>
                                    <div className="mb-4 form-floating" >
                                        <ReactInputVerificationCode
                                            length={6}
                                            onChange={(value) => {
                                                setCode(value)
                                            }}
                                            value={code}
                                        />
                                    </div>
                                    {/* Resend email button */}
                                    <div className="mb-4">
                                        <button type="button" className="btn btn-danger" onClick={() => {
                                            toast.promise(resendEmail(usedEmail),
                                                {
                                                    loading: t('home:email_sending'),
                                                    success: t('home:email_sent'),
                                                    error: t('home:email_error')
                                                });
                                        }}>{t('home:resend_email')}</button>
                                    </div>
                                    <button type="submit" className=" btn btn-primary btn-block" disabled={code.length !== 6} data-bs-dismiss="modal">{t('common:confirm')}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LoginComponents