import axios from "axios";
import { AsyncChat, AsyncChatDetail } from "models/chatModels";
import { Company, CompanyDetail } from "models/companyModel";
import { QuizAnswer, QuizArgument, QuizModule, QuizQuestion, QuizSlide, QuizUnit, UserAnswer, UserArgument, UserModule, UserQuestion, UserUnit } from "models/eduquizModels";
import { Expert, ExpertDetail } from "models/expertModel";
import { Goal, GoalDetail } from "models/progressionModels";
import { UserProfile } from "models/userModels";
import { toast } from "react-hot-toast";
import { Playlist, PlaylistDetail, UserVideo, UserVideoDetail, Video, VideoDetail } from "models/videoModels";
import { useAuthStore } from "stores/authStore";
import Config from "utils/config";
import { ShopItem } from "models/shopModels";
import { Partner } from "models/partnerModel";
import { Order } from "models/orderModel";

const baseUrl = Config.baseUrl;

/* axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);
 */

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();


axios.interceptors.response.use(
    (response) => {
        // Do something with response data
        return response;
    },
    async (error) => {
        // handle token expired error
        if (!error.response) return Promise.reject({ message: 'server_error' });
        if (error.response.status === 403) {
            /*   return; */
            /* alert('Token expired'); */
            const token = await useAuthStore.getState().refreshToken();
            console.log('New token', token);
            // retry with new token
            const config = error.config;
            config.headers['Authorization'] = 'Bearer ' + token;
            return axios.request(config);
        } else {
            /*             if (error.response.status === 403) {
                            await useAuthStore.getState().logout();
                        } */
            return Promise.reject(error);
        }
    }
);
const endpoints = {
    auth: baseUrl + 'auth/',
    user: baseUrl + 'users/',
    education: baseUrl + 'education/',
    arguments: baseUrl + 'argument/',
    modules: baseUrl + 'module/',
    units: baseUrl + 'unit/',
    slides: baseUrl + 'slide/',
    quiz: baseUrl + 'quiz/',
    questions: baseUrl + 'question/',
    answers: baseUrl + 'answer/',
    documents: baseUrl + 'documents/',
    lifePoints: baseUrl + 'lifePoint/',
    experiencePoints: baseUrl + 'experiencePoint/',
    shopItems: baseUrl + 'shopItem/',
    userShopItems: baseUrl + 'userShopItem/',
    gems: baseUrl + 'gem/',
    partners: baseUrl + 'partner/',
    company: baseUrl + 'company/',
    ranking: baseUrl + 'ranking/',
    goal: baseUrl + 'goal/',
    users: baseUrl + 'users/',
    asyncChat: baseUrl + 'asyncChat/',
    experts: baseUrl + 'expert/',
    video: baseUrl + 'video/',
    playlist: baseUrl + 'playlist/',
    orders: baseUrl + 'order/',
    userVideos: baseUrl + 'userVideo/',
    userArguments: baseUrl + 'userArgument/',
    userModules: baseUrl + 'userModule/',
    userUnits: baseUrl + 'userUnit/',
    userQuestions: baseUrl + 'userQuestion/',
    userAnswers: baseUrl + 'userAnswer/',
}

const defaultHeaders = (): { 'Content-Type': string; 'Authorization': string } => {
    const token = useAuthStore.getState().authAccessToken;
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
}
const formDataHeaders = () => {
    const token = useAuthStore.getState().authAccessToken;
    return {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token,
    }
}

export const ApiService = {
    auth: {
        signup: async (username: string, password: string) => {
            return axios.post(`${endpoints.auth}signUp`, { username, password });
        },
        signIn: async (userSub: string, password: string) => {
            const params = new URLSearchParams();
            params.append('username', userSub);
            params.append('pass', password);
            return axios.post(`${endpoints.auth}signIn`, params, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            }).then(
                (response) => {
                    const { accessToken, refreshToken } = response.data;
                    return { accessToken, refreshToken };
                }
            ) as Promise<{ accessToken: string; refreshToken: string }>;
        },
        confirmUser: async (username: string, code: string) => {
            return axios.post(`${endpoints.auth}confirm-user`, new URLSearchParams({ username, code }), {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            });
        },
        refreshToken: async (userSub: string, refreshToken: string) => {
            const params = new URLSearchParams();
            params.append('username', userSub);
            params.append('refreshToken', refreshToken);
            return axios.post(`${endpoints.auth}signIn`, params, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                const { accessToken, refreshToken } = response.data;
                return { accessToken, refreshToken };
            }) as Promise<{ accessToken: string, refreshToken: string }>;
        },
        info: async (username: string) => {
            return axios.get(`${endpoints.auth}info`, {
                params: { username }
            }).then(
                (response) => {
                    const { username, userStatus } = response.data;
                    /*                     const group = (response.data.userAttributes as Array<any>).filter((attr: { name: string; value: any }) => attr.name === 'groups')[0].value;
                                        // parse group string into array
                    
                                        const userGroup = group.replace(/[[\]]/g, ''); */
                    return { username, userStatus/* , userGroup */ }
                });
        },
        changePassword: async (username: string, newPassword: string, oldPassword?: string, verificationCode?: string) => {
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('passsword', newPassword);
            if (oldPassword) params.append('oldPasssword', oldPassword);
            if (verificationCode) params.append('code', verificationCode);
            return axios.post(`${endpoints.auth}change-password`, params, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            }).then(
                (response) => {
                    return response.status;
                }
            );
        },

        userStatus: async (username: string, enabled: boolean) => {
            return axios.post(`${endpoints.auth}user-status`, { username, enabled }).then(
                (response) => {
                    return response.status;
                }
            );
        },

        manageGroup: async (username: string, add: boolean, group: string) => {
            return axios.post(`${endpoints.auth}manage-group`, { username, add, group }).then(
                (response) => {
                    return response.status;
                }
            );
        },

        resetPassword: async (username: string) => {
            const params = new URLSearchParams();
            params.append('username', username);
            return axios.post(`${endpoints.auth}reset-password`, params).then(
                (response) => {
                    return response.status;
                }
            );
        },
        deleteUser: async (username: string) => {
            return axios.post(`${endpoints.auth}delete-user`, { username },
                { headers: defaultHeaders(), }
            ).then(
                (response) => {
                    return response.status;
                }
            );
        },
        logout: async () => {
            return axios.head(`${endpoints.auth}logout`, {
                headers: defaultHeaders(),
            }).then(
                (response) => {
                    return response.status;
                }
            );
        },
    },
    user: {
        getUserByAuthProfileId: async (userAuthProfileId: string): Promise<UserProfile> => {
            return axios.get(`${endpoints.user}get`, {
                params: {
                    authProfileId: userAuthProfileId,
                    version: '1.0.0',
                    system: 'bo',
                },
                headers: defaultHeaders(),
            }).then((response) => response.data as UserProfile);
        },
        searchUsers: async (): Promise<UserProfile[]> => {
            return axios.get(`${endpoints.user}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.userProfiles);
        },
        putUser(user: { userProfileId: string; }) {
            return axios.put(`${endpoints.user}${user.userProfileId}`, user, {
                headers: defaultHeaders(),
            });
        }
    },
    education: {
        getUserArgumentsHistory: async (userProfileId?: string) => {
            return axios.get(`${endpoints.userArguments}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    userProfileId: userProfileId
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.userArgumentsList as UserArgument[]);
        },
        getUserModulesHistory: async (userProfileId?: string) => {
            return axios.get(`${endpoints.userModules}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    userProfileId: userProfileId
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.userModulesList as UserModule[]);
        },
        getUserUnitsHistory: async (userProfileId?: string) => {
            return axios.get(`${endpoints.userUnits}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    userProfileId: userProfileId
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.userUnitsList as UserUnit[]);
        },
        getUserQuestionsHistory: async (userProfileId?: string) => {
            return axios.get(`${endpoints.userQuestions}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    userProfileId: userProfileId
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.userQuestionsList as UserQuestion[]);
        },
        getUserAnswersHistory: async (userProfileId?: string) => {
            return axios.get(`${endpoints.userAnswers}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    userProfileId: userProfileId
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.userAnswersList as UserAnswer[]);
        },
        getArguments: async (companyId: string | null) => {
            return axios.get(`${endpoints.arguments}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    informationDetails: "argument,module,unit,slide",
                    companyId: companyId ?? null
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.argumentsList);
        },
        getCompleteArguments: async (companyId: string | null) => {
            return axios.get(`${endpoints.arguments}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    informationDetails: "argument,module,unit,slide,question,answer",
                    companyId: companyId ?? null
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.argumentsList);
        },
        searchUserArguments: async (userProfileId: string, from?: number, limit?: number, companyId?: string | null) => {
            return axios.get(`${endpoints.arguments}search`, {
                params: {
                    from: from ?? 0,
                    limit: limit ?? 999999,
                    informationDetails: "argument,module,unit",
                    userProfileId: userProfileId,
                    companyId: companyId ?? null
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.argumentsList as QuizArgument[]);
        },
        getUserArgument: async (argumentId: string, userProfileId: string) => {
            return axios.get(`${endpoints.arguments}${argumentId}`, {
                params: {
                    argumentId: argumentId,
                    informationDetails: "argument,module,unit",
                    userProfileId: userProfileId,
                    companyId: null
                },
                headers: defaultHeaders(),
            }).then((response) => response.data as QuizArgument);
        },
        getUserArguments: async (userProfileId: string, from?: number, limit?: number) => {
            return axios.get(`${endpoints.arguments}search`, {
                params: {
                    from: from ?? 0,
                    limit: limit ?? 999999,
                    informationDetails: "argument,module",
                    userProfileId: userProfileId,
                    companyId: null
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.argumentsList as QuizArgument[]);
        },
        getUserArgumentsWithUnits: async (userProfileId: string) => {
            return axios.get(`${endpoints.arguments}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    informationDetails: "argument,module,units",
                    userProfileId: userProfileId
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.argumentsList as QuizArgument[]);
        },
        getModule: async (moduleId: string) => {
            return axios.get(`${endpoints.modules}${moduleId}`, {
                headers: defaultHeaders(),
                params: {
                    informationDetails: "argument,module,unit, slide, question, answer"
                }
            }).then((response) => response.data)
        },
        getUserModule: async (moduleId: string, userProfileId: string) => {
            return axios.get(`${endpoints.modules}${moduleId}`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId,
                    informationDetails: "argument,module,unit"
                }
            }).then((response) => response.data)
        },
        getUserUnit: async (unitId: string, userProfileId: string) => {
            return axios.get(`${endpoints.units}${unitId}`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId,
                    informationDetails: "argument,module,unit,slide,question,answer"
                }
            }).then((response) => response.data as QuizUnit);
        },
        getUnit: async (unitId: string) => {
            return axios.get(`${endpoints.units}${unitId}`, {
                headers: defaultHeaders(),
                params: {
                    informationDetails: "argument,module,unit,slide,question,answer"
                }
            }).then((response) => response.data as QuizUnit);
        },
        /* PUT */
        putArgument: async (argument: QuizArgument) => {
            return axios.put(`${endpoints.arguments}${argument.argumentId}`, argument, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        putModule: async (quizModule: QuizModule) => {
            return axios.put(`${endpoints.modules}${quizModule.moduleId}`, quizModule, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        putUnit: async (unit: QuizUnit) => {
            return axios.put(`${endpoints.units}${unit.unitId}`, unit, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        putSlide: async (slide: { slideId: any; }) => {
            return axios.put(`${endpoints.slides}${slide.slideId}`, slide, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        putQuestion: async (question: QuizQuestion) => {
            return axios.put(`${endpoints.questions}${question.questionId}`, question, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        putAnswer: async (answer: { answerId: string; }) => {
            return axios.put(`${endpoints.answers}${answer.answerId}`, answer, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        /* POST */
        postArgument: async (argument: any) => {
            return axios.post(`${endpoints.arguments}`, argument, {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        postModule: async (quizModule: any) => {
            return axios.post(`${endpoints.modules}`, quizModule, {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        postUnit: async (unit: any) => {
            return axios.post(`${endpoints.units}`, unit, {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        postSlide: async (slide: any) => {
            return axios.post(`${endpoints.slides}`, slide, {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        postQuestion: async (question: any) => {
            return axios.post(`${endpoints.questions}`, question, {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        postAnswer: async (answer: any) => {
            return axios.post(`${endpoints.answers}`, [answer], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        postAnswers: async (answer: any) => {
            return axios.post(`${endpoints.answers}`, answer, {
                headers: defaultHeaders(),
            }).then((response) => response.data as QuizAnswer[] ?? null);
        },
        getQuestion: async (questionId: string) => {
            return axios.get(`${endpoints.questions}${questionId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data ?? null as QuizQuestion | null) as Promise<QuizQuestion | null>;
        },
        getAnswer: async (answerId: string) => {
            return axios.get(`${endpoints.answers}${answerId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data ?? null as QuizAnswer | null) as Promise<QuizAnswer | null>;
        },
        /* DELETE */
        deleteArgument: async (argumentId: string) => {
            return axios.delete(`${endpoints.arguments}${argumentId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        deleteModule: async (moduleId: string, argument: QuizArgument) => {
            return axios.delete(`${endpoints.modules}${moduleId}`, {
                headers: defaultHeaders(),
            }).then((response) => {
                if (response.status === 200) {
                    return ApiService.education.putArgument({
                        argumentId: argument.argumentId,
                        argumentDetail: {
                            ...argument.argumentDetail,
                            moduleIds: argument.argumentDetail.moduleIds.filter((id: any) => id !== moduleId),
                            modules: (argument.argumentDetail.modules ?? []).filter((module: QuizModule) => module.moduleId !== moduleId)
                        }
                    });
                }
            });
        },
        deleteUnit: async (unitId: string, module: QuizModule) => {
            return axios.delete(`${endpoints.units}${unitId}`, {
                headers: defaultHeaders(),
            }).then((response) => {
                if (response.status === 200) {
                    return ApiService.education.putModule({
                        moduleId: module.moduleId,
                        moduleDetail: {
                            ...module.moduleDetail,
                            unitIds: module.moduleDetail.unitIds.filter((id: any) => id !== unitId),
                            units: (module.moduleDetail.units ?? []).filter((unit: QuizUnit) => unit.unitId !== unitId)
                        }
                    });
                }
            });
        },
        deleteSlide: async (slideId: any, unit: QuizUnit) => {
            return axios.delete(`${endpoints.slides}${slideId}`, {
                headers: defaultHeaders(),
            }).then((response) => {
                if (response.status === 200) {
                    return ApiService.education.putUnit({
                        unitId: unit.unitId,
                        unitDetail: {
                            ...unit.unitDetail,
                            slideIds: unit.unitDetail.slideIds.filter((id: any) => id !== slideId),
                            slides: (unit.unitDetail.slides ?? []).filter((slide: { slideId: any; }) => slide.slideId !== slideId)
                        }
                    });
                }
            });
        },
        repairModule: async (module: QuizModule) => {
            //if unitsIds are longer than units:
            if (module.moduleDetail.unitIds.length > (module.moduleDetail.units ?? []).length) {
                // remove any extra unitIds not present in units
                module.moduleDetail.unitIds = module.moduleDetail.unitIds.filter((id: any) => (module.moduleDetail.units ?? []).find((unit: QuizUnit) => unit.unitId === id));
                await ApiService.education.putModule(module);
            }
        },
        deleteSlideImage: async (slide: QuizSlide) => {
            slide.slideDetail.bannerImage = null;
            slide.slideDetail.bannerId = null;
            return ApiService.education.putSlide(slide);
        },
        deleteQuestion: async (question: QuizQuestion, unit: QuizUnit) => {
            return axios.delete(`${endpoints.questions}${question.questionId}`, {
                headers: defaultHeaders(),
            }).then((response) => {

                (question.questionDetail.answerIds ?? []).forEach((answerId: string) => {
                    axios.delete(`${endpoints.answers}${answerId}`, {
                        headers: defaultHeaders(),
                    });
                });
                ApiService.education.putUnit({
                    unitId: unit.unitId,
                    unitDetail: {
                        ...unit.unitDetail,
                        questionIds: unit.unitDetail.questionIds.filter((id: any) => id !== question.questionId),
                        questions: (unit.unitDetail.questions ?? []).filter((q: { questionId: string; }) => q.questionId !== question.questionId)
                    }
                });

            });
        },
        deleteAnswer: async (answerId: string, question: QuizQuestion) => {
            return axios.delete(`${endpoints.answers}${answerId}`, {
                headers: defaultHeaders(),
            }).then((response) => {
                ApiService.education.putQuestion({
                    questionId: question.questionId,
                    questionDetail: {
                        ...question.questionDetail,
                        answerIds: question.questionDetail.answerIds.filter((id: any) => id !== answerId),
                        answers: (question.questionDetail.answers ?? []).filter((answer: { answerId: string; }) => answer.answerId !== answerId)
                    }
                });
            });
        },
        duplicateAllArguments: async (companyId: string) => {
            return axios.post(`${endpoints.arguments}duplicate-all/${companyId}`, {}, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        fixEverything: async () => {
            const companyId = "11711b1e-4848-41a6-971c-9b0812ed2989";
            const args = await axios.get(`${endpoints.arguments}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                    companyId: companyId,
                    informationDetails: "argument,module"
                }
            });
            // loop args with for
            for (const arg of args.data.argumentsList) {
                // loop modules
                for (const mod of arg.argumentDetail.modules ?? []) {
                    await ApiService.education.fixModuleCompanyId(mod.moduleId);
                }
            }
        },
        fixModuleCompanyId: async (moduleId: string) => {

            const companyId = "11711b1e-4848-41a6-971c-9b0812ed2989";
            // get a module
            const quizModule = await ApiService.education.getModule(moduleId);
            toast('Fixing module ' + quizModule.moduleDetail.title.it);
            // set companyId on units
            const units = quizModule.moduleDetail.units ?? [];
            for (const unit of units) {
                if (unit.unitDetail.companyId !== companyId) {
                    unit.unitDetail.companyId = companyId;
                    await ApiService.education.putUnit(unit);
                    toast.success('Fixed unit ' + unit.unitDetail.title.it + ' on module ' + quizModule.moduleDetail.title.it);
                }
                //then set companyId on slides
                const slides = unit.unitDetail.slides ?? [];
                for (const slide of slides) {
                    if (slide.slideDetail.companyId !== companyId) {
                        slide.slideDetail.companyId = companyId;
                        await ApiService.education.putSlide(slide);
                        toast.success('Fixed slide ' + slide.slideDetail.title.it + ' on unit ' + unit.unitDetail.title.it);
                    }
                }
                // then set companyId on questions
                const questionIds = unit.unitDetail.questionIds ?? [];

                for (const qid of questionIds) {
                    const question = await ApiService.education.getQuestion(qid);
                    if (!question) {
                        toast.error('Question not found: ' + qid);
                        console.error('Question not found: ' + qid);
                        //fix questionIds
                        unit.unitDetail.questionIds = unit.unitDetail.questionIds.filter((id: any) => id !== qid);
                        await ApiService.education.putUnit(unit);
                        console.log('Fixed questionIds on unit ' + unit.unitDetail.title.it)
                        continue;
                    }
                    if (question.questionDetail.companyId !== companyId) {
                        question.questionDetail.companyId = companyId;
                        await ApiService.education.putQuestion(question);
                        toast.success('Fixed question ' + question.questionDetail.title.it + ' on unit ' + unit.unitDetail.title.it);
                    }
                    // then set companyId on answers
                    const answerIds = question.questionDetail.answerIds ?? [];
                    for (const answerId of answerIds) {
                        const answer = await ApiService.education.getAnswer(answerId);
                        if (!answer) {
                            toast.error('Answer not found: ' + answerId);
                            console.error('Answer not found: ' + answerId);
                            //fix answerIds
                            question.questionDetail.answerIds = question.questionDetail.answerIds.filter((id: any) => id !== answerId);
                            await ApiService.education.putQuestion(question);
                            console.log('Fixed answerIds on question ' + question.questionDetail.title.it);
                            continue
                        }
                        if (answer.answerDetail.companyId !== companyId) {
                            answer.answerDetail.companyId = companyId;
                            console.log(answer.answerDetail);
                            await ApiService.education.putAnswer(answer);
                            toast.success('Fixed answer ' + answer.answerDetail.title.it + ' on question ' + question.questionDetail.title.it);
                        }
                    }
                }
            }
            toast.success('Fixed module ' + quizModule.moduleDetail.title.it);
        },
        copyArgument: async (argumentId: string, companyId: string) => {
            return axios.post(`${endpoints.arguments}duplicate/${argumentId}/${companyId}`, {}, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    documents: {
        getDocument: async (documentId: any) => {
            return axios.get(`${endpoints.documents}${documentId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data);
        },
        postDocument: async (data: any) => {
            return axios.post(`${endpoints.documents}`, [{ document: data }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        putDocument: async (document: any) => {
            return axios.put(`${endpoints.documents}${document.documentId}`, document, {
                headers: defaultHeaders(),
            }).then((response) => response.data ?? null);
        },
        uploadFileToDocument: async (documentId: any, file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            return axios.put(`${endpoints.documents}upload/${documentId}`, formData, {
                headers: formDataHeaders(),
            }).then((response) => response.data ?? null);
        },
        makeDocumentPermanent: async (ownerId: string, documentId: string) => {
            return axios.post(`${endpoints.documents}permanent/${ownerId}`,
                [documentId], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        search: async (params: { type?: any; ownerId?: string }) => {
            return axios.get(`${endpoints.documents}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                    type: params.type,
                    ownerId: params.ownerId,
                }
            }).then((response) => response.data.documents);
        },
        download: async (documentId: any) => {
            return axios.get(`${endpoints.documents}download/${documentId}`, {
                headers: defaultHeaders(),
                params: {
                    download: true
                },
                responseType: 'blob'
            }).then((response) => response.data);
        },
        downloadContent: async (documentId: any) => {
            return axios.get(`${endpoints.documents}download/${documentId}`, {
                headers: defaultHeaders(),
                params: {
                    download: false
                },
                responseType: 'blob'
            }).then((response) => response.data);
        },
        deleteDocument: async (documentId: any) => {
            return axios.delete(`${endpoints.documents}${documentId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    experiencePoints: {
        getExperiencePointsByUserProfileId: async (userProfileId: any) => {
            return axios.get(`${endpoints.experiencePoints}search`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId
                }
            }).then((response) => response.data.experiencePoint[0]);
        },
        getExperiencePoints: async (userProfileId: any) => {
            return axios.get(`${endpoints.experiencePoints}get`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId
                }
            }).then((response) => response.data.experiencePoint[0]);
        },
        search: async (companyId?: string) => {
            return axios.get(`${endpoints.experiencePoints}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 9999999,
                    companyId: companyId ?? null
                }
            }).then((response) => response.data.experiencePoint);
        }
    },
    lifePoints: {
        getLifePointsByUserProfileId: async (userProfileId: any) => {
            return axios.get(`${endpoints.lifePoints}search`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId
                }
            }).then((response) => response.data.lifePoint[0]);
        }
    },
    gems: {
        getGemsByUserProfileId: async (userProfileId: any) => {
            return axios.get(`${endpoints.gems}search`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId
                }
            }).then((response) => response.data.gem[0]);
        }
    },
    shop: {
        getShopItems: async () => {
            return axios.get(`${endpoints.shopItems}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 9999999,
                }
            }).then((response) => response.data.shopItem ?? response.data.shopItems as ShopItem[]);
        },
        getUserShopItems: async (userProfileId: any) => {
            return axios.get(`${endpoints.userShopItems}search`, {
                headers: defaultHeaders(),
                params: {
                    userProfileId: userProfileId,
                    from: 0,
                    limit: 9999999,
                    status: 1
                }
            }).then((response) => response.data.userShopItem);
        },
        putShopItem: async (shopItem: { shopItemId: any; }) => {
            return axios.put(`${endpoints.shopItems}${shopItem.shopItemId}`, shopItem, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        postShopItem: async (shopItem: any) => {
            return axios.post(`${endpoints.shopItems}`, [shopItem], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        deleteShopItem: async (shopItem: any) => {
            return axios.delete(`${endpoints.shopItems}${shopItem.shopItemId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    partners: {
        searchPartners: async () => {
            return axios.get(`${endpoints.partners}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 9999999,
                }
            }).then((response) => response.data.partner ?? response.data.partners);
        },
        putPartner: async (partner: Partner) => {
            return axios.put(`${endpoints.partners}${partner.partnerId}`, partner, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        postPartner: async (partner: any) => {
            return axios.post(`${endpoints.partners}`, [partner], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        }
    },
    company: {
        getCompany: async (companyId: any) => {
            return axios.get(`${endpoints.company}${companyId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data);
        },
        putCompany: async (company: Company) => {
            return axios.put(`${endpoints.company}${company.companyId}`, company, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        postCompany: async (companyDetail: CompanyDetail) => {
            return axios.post(`${endpoints.company}`, [{ companyDetail: companyDetail }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        searchCompany: async () => {
            return axios.get(`${endpoints.company}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 9999999,
                }
            }).then((response) => response.data.company);
        },
        deleteCompany: async (company: Company) => {
            let argsToDelete = await axios.get(`${endpoints.arguments}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    informationDetails: "argument,module,unit",
                    companyId: company.companyId!
                },
                headers: defaultHeaders(),
            });
            const argumentsList = argsToDelete.data.argumentsList as QuizArgument[];
            // args cycle
            for (const arg of argumentsList ?? []) {
                // modules cycle
                for (const mod of arg.argumentDetail.modules ?? []) {
                    // units cycle
                    for (const unit of mod.moduleDetail.units ?? []) {
                        // For each slideId in unitDetail.slideIds, delete the slide

                        for (const slideId of unit.unitDetail.slideIds ?? []) {
                            // slides delete
                            await axios.delete(`${endpoints.slides}${slideId}`, {
                                headers: defaultHeaders(),
                            });
                        }
                        // questions cycle
                        // fetch all questions using questionIds
                        const questions = await axios.get(`${endpoints.questions}search`, {
                            headers: defaultHeaders(),
                            params: {
                                from: 0,
                                limit: 999999,
                                unitId: unit.unitId,
                                informationDetails: "question"
                            }
                        });
                        console.log('questions', questions.data.questionsList);
                        for (const question of questions.data.questionsList ?? []) {
                            // answers cycle
                            for (const answerId of question.questionDetail.answerIds ?? []) {
                                // answers delete
                                await axios.delete(`${endpoints.answers}${answerId}`, {
                                    headers: defaultHeaders(),
                                });
                            }
                            // questions delete
                            await axios.delete(`${endpoints.questions}${question.questionId}`, {
                                headers: defaultHeaders(),
                            });
                        }
                        // units delete
                        await axios.delete(`${endpoints.units}${unit.unitId}`, {
                            headers: defaultHeaders(),
                        });
                    }
                    // modules delete
                    await axios.delete(`${endpoints.modules}${mod.moduleId}`, {
                        headers: defaultHeaders(),
                    });
                }
                // args delete
                await axios.delete(`${endpoints.arguments}${arg.argumentId}`, {
                    headers: defaultHeaders(),
                });
            }
            console.log('DELETED ALL ARGUMENTS');
            return axios.delete(`${endpoints.company}${company.companyId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    ranking: {
        getRankingList: async (companyId: string) => {
            return axios.get(`${endpoints.user}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                },
                headers: defaultHeaders(),
            }).then(async (response) => {
                /*                 return response.data.userProfiles; */

                let data: UserProfile[] = [];
                const expList = await ApiService.experiencePoints.search(companyId);
                for (let i = 0; i < response.data.userProfiles.length; i++) {
                    // find the user's experience points
                    const userExperiencePoints = expList.find((exp: any) => exp.experiencePointDetail.userProfileId === response.data.userProfiles[i].userProfileId);
                    data.push(
                        {
                            ...response.data.userProfiles[i],
                            experiencePoints: userExperiencePoints?.experiencePointDetail?.currentExperiencePoints ?? 0
                        }
                    );
                }
                data.sort((a: any, b: any) => {
                    return b.experiencePoints - a.experiencePoints;
                });
                return data;
            });
        }
    },
    goal: {
        getGoalList: async (): Promise<Goal[]> => {
            return axios.get(`${endpoints.goal}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 9999999,
                }
            }).then((response) => response?.data?.goalList ?? []);
        },
        create: async (goalDetail: GoalDetail) => {
            return axios.post(`${endpoints.goal}`, [{ goalDetail: goalDetail }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] as Goal ?? null);
        },
        update: async (goal: Goal) => {
            return axios.put(`${endpoints.goal}${goal.goalId}`, goal, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        delete: async (goalId: string) => {
            return axios.delete(`${endpoints.goal}${goalId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    users: {
        create: async (users: {
            userProfileDetail: {
                companyId: string,
                confirmed: boolean;
                dateLastUpdate: string;
                email: string;
                emailVerified: boolean;
                enabled: boolean;
                locale: string;
                mobilePhoneNumber: string;
                mobilePhoneVerified: boolean;
                name: string;
                registrationDate: string;
                surname: string;
                group?: string;
            }
        }[]) => {
            return axios.post(`${endpoints.users}?createUser=true`, users, {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        search: async () => {
            return axios.get(`${endpoints.users}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 9999999,
                }
            }).then((response) => response.data.userProfiles);
        },
        get: async (userProfileId: string) => {
            return axios.get(`${endpoints.users}${userProfileId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data as UserProfile);
        },
        delete: async (userProfileId: string) => {
            return axios.delete(`${endpoints.users}${userProfileId}?deleteCognito=true`, {
                headers: defaultHeaders(),
            }).then((response) => response).catch((error) => {
                //Handle Cognito message
                if (error?.response?.data?.message?.includes('User does not exist')) {
                    throw new Error('user_not_found_on_provider');
                }
            });
        },
        deleteData: async (userProfileId: string) => {
            return axios.delete(`${endpoints.users}${userProfileId}?deleteCognito=false`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        resetPassword: async (userProfileId: string) => {
            return axios.post(`${endpoints.users}recreate/${userProfileId}`, {}, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        put: async (user: UserProfile) => {
            return axios.put(`${endpoints.users}${user.userProfileId}`, user, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    asyncChat: {
        search: async (isClosed?: boolean) => {
            return axios.get(`${endpoints.asyncChat}search`, {
                params: {
                    from: 0,
                    limit: 999999,
                    isClosed: isClosed ?? false
                },
                headers: defaultHeaders(),
            }).then((response) => response.data.asyncChat ?? []);
        },
        get: async (chatId: string) => {
            return axios.get(`${endpoints.asyncChat}${chatId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data as AsyncChat);
        },
        create: async (chat: AsyncChatDetail) => {
            return axios.post(`${endpoints.asyncChat}`, [{ asyncChatDetail: chat }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        update: async (chat: AsyncChat) => {
            return axios.put(`${endpoints.asyncChat}${chat.asyncChatId}`, chat, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        delete: async (chatId: string) => {
            return axios.delete(`${endpoints.asyncChat}${chatId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    experts: {
        search: async () => {
            return axios.get(`${endpoints.experts}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                }
            }).then((response) => response.data.expert);
        },
        create: async (expert: ExpertDetail) => {
            return axios.post(`${endpoints.experts}`, [{ expertDetail: expert }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        delete: async (expertId: string) => {
            return axios.delete(`${endpoints.experts}${expertId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        update: async (expert: Expert) => {
            return axios.put(`${endpoints.experts}${expert.expertId}`, expert, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    video: {
        search: async () => {
            return axios.get(`${endpoints.video}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                }
            }).then((response) => response.data.video as Video[]);
        },
        create: async (video: VideoDetail) => {
            return axios.post(`${endpoints.video}`, [{ videoDetail: video }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] ?? null);
        },
        delete: async (videoId: string) => {
            return axios.delete(`${endpoints.video}${videoId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        update: async (video: Video) => {
            return axios.put(`${endpoints.video}${video.videoId}`, video, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    playlist: {
        search: async () => {
            return axios.get(`${endpoints.playlist}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                    loadVideos: true
                }
            }).then((response) => response.data.playlist as Playlist[]);
        },
        create: async (playlist: PlaylistDetail) => {
            return axios.post(`${endpoints.playlist}`, [{ playlistDetail: playlist }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] as Playlist ?? null);
        },
        delete: async (playlistId: string) => {
            return axios.delete(`${endpoints.playlist}${playlistId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        update: async (playlist: Playlist) => {
            return axios.put(`${endpoints.playlist}${playlist.playlistId}`, playlist, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        get: async (playlistId: string) => {
            return axios.get(`${endpoints.playlist}${playlistId}?loadVideos=true`, {
                headers: defaultHeaders(),
            }).then((response) => response.data as Playlist);
        }
    },
    orders: {
        search: async () => {
            return axios.get(`${endpoints.orders}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                }
            }).then((response) => response.data.order as Order[]);
        },
        create: async (order: any) => {
            return axios.post(`${endpoints.orders}`, [order], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] as Order ?? null);
        },
        get: async (orderId: string) => {
            return axios.get(`${endpoints.orders}${orderId}`, {
                headers: defaultHeaders(),
            }).then((response) => response.data as Order);
        },
        update: async (order: any) => {
            return axios.put(`${endpoints.orders}${order.orderId}`, order, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        delete: async (orderId: string) => {
            return axios.delete(`${endpoints.orders}${orderId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },
    userVideos: {
        search: async (
            userProfileId?: string,
        ) => {
            return axios.get(`${endpoints.userVideos}search`, {
                headers: defaultHeaders(),
                params: {
                    from: 0,
                    limit: 999999,
                    userProfileId: userProfileId,
                }
            }).then((response) => response.data.userVideo as UserVideo[]);
        },
        create: async (userVideo: UserVideoDetail) => {
            return axios.post(`${endpoints.userVideos}`, [{ userVideoDetail: userVideo }], {
                headers: defaultHeaders(),
            }).then((response) => response.data[0] as UserVideo ?? null);
        },
        delete: async (userVideoId: string) => {
            return axios.delete(`${endpoints.userVideos}${userVideoId}`, {
                headers: defaultHeaders(),
            }).then((response) => response);
        },
        update: async (userVideo: UserVideo) => {
            return axios.put(`${endpoints.userVideos}${userVideo.userVideoId}`, userVideo, {
                headers: defaultHeaders(),
            }).then((response) => response);
        }
    },

};

export interface ApiError {
    message: string
};
