import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { EduQuizGameStates, useEduquizStore } from "stores/eduquizStore";
import correctAudio from "assets/audio/correctAudio.mp3";
import wrongAudio from "assets/audio/wrong.mp3";
import GemCounter from "components/GemCounter";
import LifepointCounter from "components/LifepointCounter";
import XpCounter from "components/XpCounter";
import * as ReactDOM from 'react-dom/client';
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CSSTransition } from 'react-transition-group';
import { useI18nStore } from "stores/i18nStore";
import xp from "assets/images/xp.svg";

import Lottie from "lottie-react";
import coinAnimation from "assets/lotti/coin.json";
import sparkAnimation from "assets/lotti/spark.json";
import TutorialTour from "components/TutorialTour";
import Loader from "components/Loader";
import AppThemed from "components/AppThemed";
import { appThemes } from "utils/config";
import { QuizAnswer } from "models/eduquizModels";
import { routes } from "Routes";
import XPSymbol from "components/XPSymbol";

function randomPointNearRect(x: number, y: number, w: number, h: number, minDist: number, maxDist: number): [number, number] {
    const dist = (Math.random() * (maxDist - minDist) + minDist) | 0;
    x += dist;
    y += dist;
    w -= dist * 2;
    h -= dist * 2;
    if (Math.random() < w / (w + h)) { // top bottom
        x = Math.random() * w + x;
        y = Math.random() < 0.5 ? y : y + h - 1;
    } else {
        y = Math.random() * h + y;
        x = Math.random() < 0.5 ? x : x + w - 1;
    }
    return [x | 0, y | 0];
}

export default function EduQuizGameView({ ...props }) {
    const selectedUnit = useEduquizStore(state => state.selectedUnit);
    const currentQuestionIndex = useEduquizStore(state => state.currentQuestionIndex);
    const nextQuestion = useEduquizStore(state => state.nextQuestion);
    const isAnswerCorrect = useEduquizStore(state => state.isAnswerCorrect);
    const giveAnswer = useEduquizStore(state => state.giveAnswer);
    const quizState = useEduquizStore(state => state.quizState);
    //useState for answerTapped
    const [answerTapped, setAnswerTapped] = React.useState<any>(null);
    //useState for answerDialog
    const [answerDialog, setAnswerDialog] = React.useState(false);
    const navigate = useNavigate();
    const gemCounterRef = React.useRef<HTMLDivElement>();
    const xpCounterRef = React.useRef<HTMLDivElement>(null);
    // i18n 
    const getTranslation = useI18nStore(state => state.getTranslation);
    const { t } = useTranslation();
    const [continuing, setContinuing] = React.useState(false);

    useEffect(() => {
        if (answerTapped !== null) {
            let audio;
            //if answer is correct, play correctAudio, else play wrongAudio
            if (isAnswerCorrect(answerTapped)) {
                // Play a sound
                audio = new Audio(correctAudio);
            } else {
                audio = new Audio(wrongAudio);
            }
            audio.play();



            // Start a timer
            const timer = setTimeout(() => {
                // Show a dialog
                setAnswerDialog(true);
            }, 250);
            return () => clearTimeout(timer);
        }
    }, [answerTapped, isAnswerCorrect]);
    useEffect(() => {
        // scroll to top
        window.scrollTo(0, 0);
    }, [currentQuestionIndex]);

    // If the current question is not loaded yet, show the loader
    if (!selectedUnit) return (<Loader></Loader>);

    let endQuestion = false;
    const questions = selectedUnit?.unitDetail?.questions ?? [];
    if (currentQuestionIndex === questions.length) {
        endQuestion = true;
    };    // useEffect for answerTapped, when not null, play a sound, then start 1 second timer and show a dialog

    // get the current question
    const currentQuestion = questions?.[currentQuestionIndex];

    // get the color of the module
    /*     const color = selectedUnit.unitDetail.module.moduleDetail.color.replace("0xFF", "#"); */
    // get the progress percentage
    const progressPercentage = Math.round((currentQuestionIndex / questions.length) * 100);

    const onAnswerClick = async (event: any, answer: QuizAnswer) => {
        if (answerTapped) return;
        setAnswerTapped(answer);
        // get screen position of click event
        const x = event.clientX;
        const y = event.clientY;
        // get screen position of gem counter
        if (gemCounterRef.current) {
            const gemCounterRect = gemCounterRef.current.getBoundingClientRect();
            const gemCounterX = gemCounterRect.x;
            const gemCounterY = gemCounterRect.y;
            //animate sparks: create a div with a Lottie animation
            const sparks = document.createElement("div");
            sparks.className = "sparks";
            sparks.id = "sparks";
            sparks.style.left = (x - 80) + "px";
            sparks.style.top = (y - 70) + "px";
            document.body.appendChild(sparks);
            // @ts-ignore
            let root = ReactDOM.createRoot(document.getElementById("sparks"));
            root.render(<Lottie animationData={sparkAnimation} initialSegment={[8, 100]} loop={0} />);
            setTimeout(() => {
                sparks.remove();
            }, 1200);

            // if answer is correct show animation for coinsunitDetail.questions[0].questionDetail.userQuestion.userQuestionDetail.answeredOnceCorrectly
            if (isAnswerCorrect(answer) && !currentQuestion.questionDetail.userQuestion!.userQuestionDetail.answeredOnceCorrectly) {
                //loop 5 times
                for (let i = 0; i < currentQuestion.questionDetail.gemPrize ?? 5; i++) {
                    // animate coin
                    const coin = document.createElement("div");
                    coin.className = "coin coingrow";
                    coin.id = "coin" + i;
                    // place coin in a random position, positive or negative, around the clikc event
                    let pos = randomPointNearRect(x - 50, y - 50, 50, 50, 60, 100);
                    coin.style.left = pos[0] + "px";
                    coin.style.top = pos[1] + "px";
                    setTimeout(() => {
                        document.body.appendChild(coin);
                        let rootCoin = ReactDOM.createRoot(document.getElementById("coin" + i) as Element);
                        rootCoin.render(<Lottie animationData={coinAnimation} loop={0} />);
                        setTimeout(() => {
                            coin.classList.add("coinshrink");
                            coin.style.left = (gemCounterX) + "px";
                            coin.style.top = (gemCounterY - 20) + "px";
                        }, 600);
                        setTimeout(() => {
                            coin.remove();
                        }, 2200);
                    }, 120 * i);
                }
            }
        }
        if (xpCounterRef.current) {
            const xpCounterRect = xpCounterRef.current!.getBoundingClientRect();
            const gemCounterX = xpCounterRect.x;
            const gemCounterY = xpCounterRect.y;
            //animate sparks: create a div with a Lottie animation
            const sparks = document.createElement("div");
            sparks.className = "sparks";
            sparks.id = "sparks";
            sparks.style.left = (x - 80) + "px";
            sparks.style.top = (y - 70) + "px";
            document.body.appendChild(sparks);
            // @ts-ignore
            let root = ReactDOM.createRoot(document.getElementById("sparks"));
            root.render(<Lottie animationData={sparkAnimation} initialSegment={[8, 100]} loop={0} />);
            setTimeout(() => {
                sparks.remove();
            }, 1200);

            // if answer is correct show animation for coinsunitDetail.questions[0].questionDetail.userQuestion.userQuestionDetail.answeredOnceCorrectly
            if (isAnswerCorrect(answer) && !currentQuestion.questionDetail.userQuestion!.userQuestionDetail.answeredOnceCorrectly) {
                //loop 5 times
                for (let i = 0; i < currentQuestion.questionDetail.gemPrize ?? 5; i++) {
                    // animate xp
                    const coin = document.createElement("div");
                    coin.className = "coin coingrow";
                    coin.id = "xp" + i;
                    // place coin in a random position, positive or negative, around the clikc event
                    let pos = randomPointNearRect(x - 50, y - 50, 50, 50, 60, 100);
                    coin.style.left = pos[0] + "px";
                    coin.style.top = pos[1] + "px";
                    setTimeout(() => {
                        document.body.appendChild(coin);
                        let rootCoin = ReactDOM.createRoot(document.getElementById("xp" + i) as Element);
                        /*      rootCoin.render(<Lottie animationData={coinAnimation} loop={0} />); */
                        rootCoin.render(<XPSymbol />);

                        setTimeout(() => {
                            coin.classList.add("coinshrink");
                            coin.style.left = (gemCounterX) + "px";
                            coin.style.top = (gemCounterY - 20) + "px";
                        }, 600);
                        setTimeout(() => {
                            coin.remove();
                        }, 2200);
                    }, 120 * i);
                }
            }
        }
        // if last question show toast.promise
        if (currentQuestionIndex === questions.length - 1) {
            toast.promise(
                giveAnswer(answer),
                {
                    loading: t('common:saving'),
                    success: t('common:unit') + " " + t('common:completed') + "!",
                    error: t('errors:could_not_save'),
                }, {
                position: "bottom-center",
            }
            );
        } else {
            await giveAnswer(answer);
        }
    };
    return (
        <section className={`EduQuizLearnView d-flex flex-column h-100 position-relative`}>
            <TutorialTour tutorialName="gameView" />
            <div className="d-flex align-items-center mb-4 mt-3">
                <i className="fa-duotone fa-times fa-2x me-4 cursor-pointer" onClick={() => navigate(routes.eduquizModule(selectedUnit.unitDetail.module!.moduleId))} />
                <h4 className="mb-0 h5 text-truncate d-none">
                    {selectedUnit?.unitDetail.title?.it}
                </h4>
                <div className="d-flex ms-auto">
                    <AppThemed themes={[appThemes.CLASSIC, appThemes.SIDEBARS]}>
                        <LifepointCounter />
                    </AppThemed>
                    <XpCounter ref={xpCounterRef} />
                    <AppThemed themes={[appThemes.CLASSIC, appThemes.SIDEBARS]}>
                        <GemCounter ref={gemCounterRef} />
                    </AppThemed>
                </div>
            </div>
            {/* QUESTIONS */}

            {
                !endQuestion && <Carousel
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                    swipeable={false}
                    selectedItem={currentQuestionIndex}
                >
                    {questions.map((question,
                        // @ts-ignore
                        index) =>
                    (<div key={question.questionId}>
                        <div key={question.questionId + "_question"} className={"QuestionTitleCard card flex-shrink-0 flex-grow-1 mb-3"}>
                            <div className="card-body  p-4">
                                <div>
                                    <h4 className="text-start mb-0">
                                        {getTranslation(question.questionDetail.title)}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {/* ANSWER SLIDE */}
                        {(question.questionDetail.answers ?? []).map((answer, index) =>
                        (<div key={answer.answerId} onClick={(e) => onAnswerClick(e, answer)} className={`AnswerCard card  flex-shrink-0 flex-grow-1 mb-3 cursor-pointer bg-${answerTapped ? (isAnswerCorrect(answer) ? "success-solid" : (answerTapped.answerId === answer.answerId ? "danger-solid" : "secondary-subtle")) : "secondary-subtle hoverable"}`}>
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <h5 className={"text-start mb-0  " + (answerTapped ? (isAnswerCorrect(answer) ? "text-success" : (answerTapped.answerId === answer.answerId ? "text-danger" : "text-body")) : " text-body")}>
                                        {getTranslation(answer.answerDetail.title)}
                                    </h5>
                                    {/* Answer icon */}
                                    <i className={`fas fs-5 ms-auto ${answerTapped ? (isAnswerCorrect(answer) ? "text-success fa-check-circle" : (answerTapped.answerId === answer.answerId ? "text-danger fa-times-circle" : "d-none")) : "d-none"}`}></i>
                                </div>
                            </div>

                        </div >))}
                        <div className="p-5 p-md-0 "></div>
                    </div>))}
                </Carousel>
            }
            {/* Answer explanation dialog */}
            <CSSTransition
                in={answerDialog}
                timeout={600}
                classNames="quiz-explanation"
                unmountOnExit

            ><div>
                    <div className=" answer-dialog d-flex flex-column justify-content-end align-items-center fixed-bottom container-lg start-0 w-100 h-100" style={{ zIndex: "2" }}>
                        <div className={"answer-dialog-content card w-100 " + (isAnswerCorrect(answerTapped) ? "bg-success-solid" : "bg-danger-solid")} style={{ marginBottom: "6rem" }}>
                            <div className="d-flex flex-column justify-content-center align-items-start card-body p-4">
                                <h5 className={"mb-3 fw-bold " + (isAnswerCorrect(answerTapped) ? "text-success" : "text-danger")}>{isAnswerCorrect(answerTapped) ? t('eduquiz:correct_answer') : t('eduquiz:wrong_answer')}</h5>
                                <p className={"mb-3 fw-bold " + (isAnswerCorrect(answerTapped) ? "text-success" : "text-danger")}>{getTranslation(currentQuestion.questionDetail.suggestionText)}</p>
                                {/* {logo && <img src={logo} alt="logo" className="mb-3" /> */}
                                <div className="d-md-flex d-grid gap-2 flex-row-reverse w-100">
                                    {quizState !== EduQuizGameStates.gameOver &&
                                        <button className={"btn rounded-pill px-5 " + (isAnswerCorrect(answerTapped) ? "btn-success " : "btn-danger ")} onClick={() => {
                                            if (continuing) {
                                                return;
                                            }
                                            setContinuing(true);
                                            setAnswerDialog(false);
                                            setTimeout(() => {
                                                // if not last answer
                                                if (currentQuestionIndex < questions.length - 1) {
                                                    setAnswerTapped(null);
                                                }
                                                nextQuestion();
                                                setTimeout(() => {
                                                    setContinuing(false);
                                                }, 1000);
                                            }, 100);
                                        }}>
                                            {t('common:continue')}
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            {/* SLIDE PAGE CONTROLS */}
            <div className="d-flex justify-content-start align-items-center mt-auto w-100 py-3 container-lg fixed-bottom" style={{ bottom: "0" }}>
                {/* Page indicator */}
                <div className="d-flex col col-md-5 card rounded-pill p-3 flex-row align-items-center">
                    {/*Progress bar */}
                    <div className="progress col rounded-pill" style={{ width: "10rem", height: "1rem" }}>
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: progressPercentage + "%" }} ></div>
                    </div> <div className="ms-3">
                        {
                            t('eduquiz:questions_completed', { currentUnits: currentQuestionIndex + 1, totalUnits: (selectedUnit.unitDetail.questions ?? []).length })
                        }
                    </div>
                </div>

            </div>
        </section >
    );
}