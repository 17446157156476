import Loader from 'components/Loader';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useRankingStore } from 'stores/rankingStore'
import { useTitle } from 'utils/functions';
import { useUserStore } from 'stores/userStore';
import SecondaryHeader from 'components/SecondaryHeader';
import Config, { appThemes } from 'utils/config';
import XPSymbol from 'components/XPSymbol';

function RankingPage() {

    const rankingList = useRankingStore(state => state.rankingList);
    const initRanking = useRankingStore(state => state.initRanking);
    const { t } = useTranslation();
    useEffect(() => {
        if (rankingList === null) {
            initRanking();
        }
    }, [rankingList, initRanking]);
    useTitle(t('ranking:title'));
    return (
        <section className='RankingPage d-flex flex-column pb-5'>
            <style>
                {`
                  :root {
                    --gold: #ffb338;
                    --silver: #c0c0c0;
                    --bronze: #cd7f32;
                    --light-shadow: #77571d;
                    --dark-shadow: #947033;
                  }

                  .bg{
                    background-clip: text;  
                    -webkit-background-clip: text;
                    grid-area: overlap;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 4px transparent;
                    background-image: repeating-linear-gradient( 5deg,  
                      var(--gold) 0% , 
                      var(--light-shadow) 23%, 
                      var(--gold) 31%);
                  }
                  .bg-silver{
                    background-clip: text;  
                    -webkit-background-clip: text;
                    grid-area: overlap;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 4px transparent;
                    background-image: repeating-linear-gradient( 5deg,  
                      var(--silver) 0% , 
                      var(--light-shadow) 23%, 
                      var(--silver) 31%);
                  }
                  .bg-bronze{
                    background-clip: text;  
                    -webkit-background-clip: text;
                    grid-area: overlap;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 4px transparent;
                    background-image: repeating-linear-gradient( 5deg,  
                      var(--bronze) 0% , 
                      var(--light-shadow) 23%, 
                      var(--bronze) 31%);
                  }
                  `}
            </style>
            <SecondaryHeader title={t('ranking:title')} backTarget={Config.appTheme === appThemes.SCHOOL ? "/welfare/dashboard" : "/welfare/profile"} />
            {/*             <PageTitle title={t('ranking:title')}>
                <InfoButton id={'progPageInfo'} title={t('ranking:info_modal_title')}>
                    <p>{t('ranking:info_modal_text')}</p>
                </InfoButton>
            </PageTitle> */}
            <div className='col-12 col-md-12 mb-3'>
                <div className='container-fluid p-0 '>
                    <div className="row align-items-end gx-3">
                        <RankingPodiumItem rankingList={rankingList} ranking={1} />
                        <RankingPodiumItem rankingList={rankingList} ranking={0} />
                        <RankingPodiumItem rankingList={rankingList} ranking={2} />
                    </div>
                </div>
            </div>
            <div className='card  col-12 col-md-12 '>
                <div className='card-body p-0'>
                    <div className='d-flex flex-column '>
                        {rankingList != null && rankingList.map((ranking: any, index: number) => <RankingItem key={index} ranking={index + 1} data={ranking} />)}
                        {!rankingList && <Loader />}
                    </div>

                </div>

            </div>
        </section>
    )
}

export function RankingItem({ ranking, data }: { ranking: number, data: any }) {
    const user = useUserStore(state => state.user);
    const isSelf = user && user.userProfileId === data.userProfileId;
    return <div className={`d-flex align-items-center w-100 col-12 p-3  ${isSelf ? "bg-primary-subtle" : ""}`}>
        <div className='fs-4 text-primary flex-shrink-0 text-center me-1' style={{ fontWeight: "800", width: "2rem" }}>{ranking}</div>
        <div className={`d-flex align-items-center justify-content-center rounded-circle btn text-primary p-2 cursor-pointer bg-body me-3`} style={{ width: '3rem', height: '3rem' }}>
            <i className={`align-items-center d-flex justify-content-center fa-duotone text-primary fa-graduation-cap fs-4
                            `} aria-hidden="true" />
        </div>
        <div className='d-flex flex-column flex-fill text-left w-100 text-truncate' style={{ minWidth: "0" }}>
            <div className='d-flex flex-column flex-fill text-left w-100 text-truncate' style={{ minWidth: "0" }}>
                {data.userProfileDetail.name}&nbsp;{data.userProfileDetail.surname}
            </div>
            {/* email */}
            <small className='d-flex text-muted flex-column flex-fill text-left w-100 text-truncate' style={{ minWidth: "0" }}>
                {data.userProfileDetail.email}
            </small>
        </div>
        <XPSymbol />
        {/* <img src={xp} className="img-fluid ms-3 me-2  flex-shrink-0" alt="xp" style={{ height: "1rem" }}></img> */}
        <div className='fw-bolder  flex-shrink-0'>
            {data.experiencePoints}
        </div>
    </div>
}

export function RankingPodiumItem({ rankingList, ranking }: { rankingList: any, ranking: number }) {
    let rankingClass = "bg";
    switch (ranking) {
        case 0: rankingClass = "bg"; break;
        case 1: rankingClass = "bg-silver"; break;
        case 2: rankingClass = "bg-bronze"; break;
        default: rankingClass = "bg"; break;
    }
    if (rankingList === null) {
        return null;
    }
    return <div className={'col-4 wrapper'}>
        <style>
            {`
                  :root {
                    --gold: #ffb338;
                    --silver: #c0c0c0;
                    --bronze: #cd7f32;
                    --light-shadow: #77571d;
                    --dark-shadow: #947033;
                  }

                  .bg{
                    background-clip: text;  
                    -webkit-background-clip: text;
                    grid-area: overlap;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 4px transparent;
                    background-image: repeating-linear-gradient( 5deg,  
                      var(--gold) 0% , 
                      var(--light-shadow) 23%, 
                      var(--gold) 31%);
                  }
                  .bg-silver{
                    background-clip: text;  
                    -webkit-background-clip: text;
                    grid-area: overlap;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 4px transparent;
                    background-image: repeating-linear-gradient( 5deg,  
                      var(--silver) 0% , 
                      var(--light-shadow) 23%, 
                      var(--silver) 31%);
                  }
                  .bg-bronze{
                    background-clip: text;  
                    -webkit-background-clip: text;
                    grid-area: overlap;
                    letter-spacing: 1px;
                    -webkit-text-stroke: 4px transparent;
                    background-image: repeating-linear-gradient( 5deg,  
                      var(--bronze) 0% , 
                      var(--light-shadow) 23%, 
                      var(--bronze) 31%);
                  }
                  `}
        </style>
        <div className='card'>
            <div className='card-body d-flex flex-column'>
                <div style={{ 'marginTop': (2 - ranking) * 1.5 + "rem" }}>
                </div>
                <span className={'text-center text-white ' + rankingClass + " " + (ranking === 0 ? "fs-1" : "fs-2")} style={{ fontWeight: "800" }}>&nbsp;{ranking + 1}°</span>
                <p className='text-center mb-0 text-truncate' style={{ overflowWrap: "normal", minWidth: "0" }}>{rankingList && rankingList[ranking] && <span>{rankingList[ranking] && rankingList[ranking].userProfileDetail.name}&nbsp;{rankingList[ranking].userProfileDetail.surname}</span>}</p>
                <div className='d-flex align-items-center justify-content-center mt-1'>
                    <XPSymbol />
                    {/* <img src={xp} className="img-fluid ms-3 me-2  flex-shrink-0" alt="xp" style={{ height: "1rem" }}></img> */}
                    <div className='fw-bolder  flex-shrink-0 me-2'>
                        {rankingList[ranking] && rankingList[ranking].experiencePoints}
                    </div>
                </div>
            </div>
        </div>
    </div >;
}
export default RankingPage