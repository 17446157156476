import { ChatMessage } from "models/chatModels";
import React, { } from "react"
import finn from "assets/images/logo-FinAi.svg";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "stores/authStore";
import { useDocumentStore, useFetchDocument } from "stores/documentStore";
import Markdown from 'marked-react';
import { motion } from "framer-motion";
import '@mdxeditor/editor/style.css'
import ChatSwitchMessageElement from "./ChatSwitchMessageElements";

const messageChildrenVariants = {
    hidden: {
        opacity: 0,
        x: -50
    },
    visible: {
        opacity: 1,
        x: 0
    },
    exit: { opacity: 0, x: -50 },
};

export default function ChatMessageElement({ message, prevMessage, onDocumentSelect, index, chatId }: { message: ChatMessage, prevMessage?: ChatMessage, chatId: string, onDocumentSelect?: (file: File) => void, index: number }) {
    const isOwnMessage = useAuthStore.getState().isUser ?
        (message.type === "Users") :
        (message.type === "HR");

    const { t } = useTranslation();
    const { document, loading, error } = useFetchDocument(message.documentId);

    const isUser = useAuthStore(state => state.isUser);
    const isHR = useAuthStore(state => state.isHR || state.isSystem || state.isAdmin);
    const isUserMessage = message.type === "Users";
    const isHRMessage = message.type === "HR";
    const isAIMessage = message.type === "AI";

    if (message.ownerId.toUpperCase() === "SYSTEM") {
        return <ChatSwitchMessageElement message={message} ></ChatSwitchMessageElement>
    }




    return <motion.div
        key={"chat-message-inner-" + chatId + "-" + index}
        variants={messageChildrenVariants}
        className={"ChatMessageElement w-100 d-flex py-2 " +
            (prevMessage?.ownerId === message.ownerId ? 'ChatMessageElement--grouped ' : ' ') +
            ((useAuthStore.getState().isUser) ? (
                isOwnMessage ? 'flex-row-reverse justify-content-start' : 'justify-content-start') : (
                (isAIMessage || isOwnMessage) ? 'flex-row-reverse justify-content-start' : 'flex-row justify-content-start'))
        }

    >
        <style>
            {`
          
          .ChatMessageElement--grouped {
           padding-top: 0 !important;
          }
          .ChatMessageElement--grouped .ChatMessageElement__avatar {
            visibility: hidden !important;
           }
           .ChatMessageElement__message  p{
            margin: 0;
          }
            .ChatMessageElement__message  li{
                margin-bottom: 1rem;
            }
                .ChatMessageElement__message  ol{
                margin-top: 1rem;
                margin-bottom: 0;
                }
          .ChatMessageElement__message   li::before, .ChatMessageElement__message li::marker{
          color: var(--bs-primary);
        }
         .ChatMessageElement__message h3{    font-size: 1.1rem;
      MARGIN-TOP: 1rem;}
               .ChatMessageElement__message h4{    font-size: 1rem;
      MARGIN-TOP: 0.5rem;}
        `}
        </style>
        {/* ADMIN AVATAR */}
        {(!isUserMessage) && <div
            className={`ChatMessageElement__avatar rounded-circle bg-light flex-shrink-0 text-primary d-flex justify-content-center align-items-center p-2 ${message.type === "AI" ? '' : 'border'}`}
            style={{ width: '36px', height: '36px' }}
        >
            {message.type === "AI" && <img src={finn} className="img-fluid" alt=""></img>}
            {message.type === "HR" && <i className="fa-duotone fa-user text-secondary"></i>}
        </div>}
        {/* USER AVATAR */}
        {
            (isUserMessage) && <div
                className="ChatMessageElement__avatar rounded-circle bg-primary-subtle flex-shrink-0 text-primary d-flex justify-content-center align-items-center "
                style={{ width: '36px', height: '36px' }}
            >
                <i className="fa-duotone fa-user"></i>
            </div>
        }
        {/* SPACER */}
        <div className="pe-2">  </div>
        {/* MESSAGE BOX */}
        <div className={`ChatMessageElement__message rounded  d-flex align-items-start p-3 flex-column
       ${isUserMessage ?
                'bg-primary-subtle text-primary ' :
                'bg-light text-dark '} 
          ${message.type === "HR" ? 'border' : ''}
          `}
        >
            {/* NORMAL MESSAGE TEXT CONTENT */}
            {<Markdown openLinksInNewTab>{message.message}</Markdown>}
            {/* DOCUMENT MESSAGE CONTENT */}
            {
                message.documentId && document && !loading && !error &&
                <button className="btn btn-link w-100 d-flex pt-3 align-items-center px-0" onClick={() => useDocumentStore.getState().downloadDocument(message.documentId!)}>
                    {document.document.type === 'application/pdf' && <i className="fas me-2 fa-file-pdf" />}
                    {document.document.type !== 'application/pdf' && <i className="fas me-2 fa-file-image" />}
                    <strong className="flex-fill text-start">
                        {document.document.title}
                    </strong>
                </button>
            }
            {/* LOADING DOCUMENT */}
            {
                message.documentId && loading && !error &&
                <div className="d-flex align-items-center justify-content-center text-center w-100 pt-3" style={{ height: "50px" }}>
                    <i className="fas me-2 fa-spinner-third fa-spin"></i>
                </div>
            }
            {/* ERROR LOADING DOCUMENT */}
            {
                message.documentId && error &&
                <div className="d-flex align-items-center pt-3">
                    <i className="fas me-2 fa-exclamation-triangle"></i>
                    <strong>
                        {t('chat:error_loading_document')}
                    </strong>
                </div>
            }
            {/* ADDITIONAL UPLOAD BUTTON */}
            {/*       {
          message.uploadDocument && <Button variant="outline-primary" className=" text-primary bg-primary-subtle mt-2 fw-normal" onClick={() => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.pdf,.png,.jpg,.jpeg';
            fileInput.click();
            fileInput.onchange = (e) => {
              const file = (e.target as HTMLInputElement).files?.[0];
              if (file && onDocumentSelect) {
                onDocumentSelect(file);
              }
            };
          }
          }>
            {t('chat:upload_document')}
          </Button>
        } */}
            {
                !isUser && <small className={"text-body-tertiary mt-1 w-100 " + (/* isOwnMessage ? "text-start" :  */"text-end")}>
                    {new Date(message.asyncChatMessageDate).toLocaleDateString()} - {new Date(message.asyncChatMessageDate).toLocaleTimeString()}
                </small>
            }
        </div>

    </motion.div >
}
