import Loader from "components/Loader";
import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { useEduquizStore } from "stores/eduquizStore";
import animation from '../../assets/lotti/pregame.json';

import StringParser from "components/StringParser";
import Lottie from "lottie-react";
import { Trans, useTranslation } from "react-i18next";
import { useI18nStore } from "stores/i18nStore";
import Config, { appThemes } from "utils/config";
import TutorialTour from "components/TutorialTour";
import { toast } from "react-hot-toast";
import FunnifinLogo from "components/FunnifinLogo";
import CircleButton from "components/CircleButton";
import { CloseButton } from "react-bootstrap";
import AppThemed from "components/AppThemed";
import PartnerLogo from "components/PartnerLogo";
import { routes } from "Routes";
export default function EduQuizLearnView({ ...props }) {
    const selectedUnit = useEduquizStore(state => state.selectedUnit);
    const currentSlideIndex = useEduquizStore(state => state.currentSlideIndex);
    const onNextSlide = useEduquizStore(state => state.nextSlide);
    const onPrevSlide = useEduquizStore(state => state.prevSlide);
    const onSetSlide = useEduquizStore(state => state.setSlide);
    const startGame = useEduquizStore(state => state.startGame);
    const slides = selectedUnit?.unitDetail?.slides;
    const getTranslation = useI18nStore(state => state.getTranslation);
    const { t } = useTranslation();
    const nextSlide = () => {
        // scroll to top
        window.scrollTo(0, 0);
        onNextSlide();
    }
    const prevSlide = () => {
        // scroll to top
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 40);
        onPrevSlide();
    }
    const setSlide = (index: number) => {
        // scroll to top
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 40);
        onSetSlide(index);
    }
    //usestate for showHints
    const [showHints, setShowHints] = React.useState(true);
    // useEffect for showhints, becomes false after 1 seconds
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setShowHints(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    const navigate = useNavigate();
    let endSlide = false;
    if (currentSlideIndex === slides?.length) {
        endSlide = true;
    };
    const currentSlide = slides?.[currentSlideIndex];
    if ((!currentSlide && !endSlide) || !slides || !selectedUnit) return (<Loader></Loader>);
    let touchStartX = 0;
    const handleSwipe = (event: React.TouchEvent<HTMLDivElement>) => {
        const touchEndX = event.changedTouches[0].clientX;
        const swipeDistance = touchEndX - touchStartX;

        if (swipeDistance > 100) {
            // Swiped right
            prevSlide();
        } else if (swipeDistance < -100) {
            // Swiped left
            nextSlide();
        }
    }
    return (
        <section className={`EduQuizLearnView d-flex flex-column h-100 position-relative user-select-none`}>
            <TutorialTour tutorialName="learnView" />
            <div className="d-flex align-items-center mb-4">
                <CloseButton className="me-4" onClick={() => navigate(routes.eduquizModule(selectedUnit.unitDetail.module!.moduleId))} />
                <h4 className="mb-0 h5 text-truncate">
                    {getTranslation(currentSlide?.slideDetail.title)}
                </h4>
                {/* SHARE BUTTON */}
                <CircleButton
                    iconClassName="fas fa-share-from-square"
                    className="ms-auto"
                    iconVariant="secondary"
                    variant="transparent" onClick={() => {
                        // Share the page
                        if (navigator.share) {
                            navigator.share({
                                title: getTranslation(currentSlide?.slideDetail?.title),
                                text: getTranslation(currentSlide?.slideDetail?.description),
                                url: window.location.href
                            });
                        } else {
                            // Fallback: copy the title to clipboard
                            navigator.clipboard.writeText(getTranslation(currentSlide?.slideDetail?.title));
                            toast.success(t('common:copied_to_clipboard'));
                        }
                    }} />
            </div>
            {/* REGULAR SLIDES */}

            {!endSlide && <Carousel
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                swipeable={true}
                selectedItem={currentSlideIndex}
            >
                {(slides ?? []).map((slide, index) =>
                (<div key={index} className="EduquizSlideCard mx-auto cursor-pointer flex-shrink-0 flex-grow-1 mx-1 " >
                    <div className="card">
                        <div className="card-body p-md-5">
                            <div className="d-flex justify-content-md-center flex-column flex-md-row">

                                <style>
                                    {`
                                .slide-viewer p{
                                    margin-bottom: 0 !important;
                                }
                                .slide-viewer *{
                                    color: var(--bs-emphasis-color) !important;
                                }
                                `}
                                </style>
                                <div className="text-start slide-viewer mb-0 pe-md-4" style={{ fontSize: "20px", color: "var(--bs-emphasis-color) !important", fontWeight: "bold" }}>
                                    <StringParser text={getTranslation(slide.slideDetail.description)} />
                                </div>
                                <div className="d-flex justify-content-center align-items-center pt-4" >
                                    {slide.slideDetail.bannerId && slide.slideDetail.bannerId !== "" && <img className="img-fluid mb-3 rounded" style={{
                                        maxHeight: "30rem",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                        minWidth: "320px"
                                    }} src={slide.slideDetail.bannerId} alt="slide banner" />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center py-3" style={{ marginBottom: "8rem" }}>
                        <div className="d-flex align-items-center justify-content-center p-3 rounded">
                            <AppThemed themes={[appThemes.CLASSIC, appThemes.SIDEBARS]}>
                                <FunnifinLogo onClick={() => { }} width={"7rem"} />
                            </AppThemed>
                            <AppThemed themes={[appThemes.SCHOOL]}>
                                <PartnerLogo onClick={() => { }} width={"250px"} />
                            </AppThemed>
                        </div>
                    </div>
                </div>))}
            </Carousel>}
            {/*  Extra ending slide */}
            {
                endSlide && <div className="card">
                    <div className="card-body">
                        <div className="mx-auto text-center d-flex flex-column justify-content-evenly py-3">
                            <h2 className="mx-auto display-4">
                                <Trans
                                    i18nKey="eduquiz:ready_to_start" // optional -> fallbacks to defaults if not provided
                                    defaults="You have finished this unit!<br></br>Are you ready for the <span>test</span>?" // optional defaultValue
                                    components={{ span: <span className="text-warning" />, br: <br /> }}
                                />
                            </h2>
                            <Lottie animationData={animation} className="img-fluid col-md-4 col-7 mx-auto ms-lg-auto d-block my-3" />
                        </div>

                    </div>
                </div>
            }
            {endSlide &&
                <div className="d-flex align-items-center justify-content-center py-3" style={{ marginBottom: "8rem" }}>
                    <div className="d-flex align-items-center justify-content-center p-3 rounded">
                        {
                            <img src={Config.companyLogo} alt="logo" className="img-fluid" style={{ width: '10rem' }} />
                        }
                    </div>
                </div>}
            {/* SWIPER */}
            {!endSlide && <div className="row position-absolute" style={{
                top: "3rem",
                left: "0",
                width: "calc(100% + 1.5rem)",
                height: "calc(100% - 8rem)",
                opacity: showHints ? "1" : "0",
                transition: "opacity 1s ease-in-out"
            }}
                onTouchStart={(event) => touchStartX = event.changedTouches[0].clientX}
                onTouchEnd={(event) => handleSwipe(event)}
            >
                <div className="col-6" onClick={() => prevSlide()}>
                </div>
                <div className="col-6" onClick={() => nextSlide()}></div>
            </div>}
            {/* SLIDE PAGE CONTROLS */}
            {<div className="shadowRilio mt-auto w-100 py-3 SlideControls bg-body-tertiary fixed-bottom " style={{ bottom: "0" }}>
                <div className="container-fluid px-3 container-md gap-3 d-flex flex-row justify-content-between align-items-stretch w-100">
                    {/* Page indicator */}
                    <div className="flex-fill order-1 order-md-1 d-none d-md-flex ">
                        <div className=" d-flex justify-content-between bg-light p-3 px-4"
                            style={{ borderRadius: "2rem" }}
                        >
                            {slides?.map((question, index) => (
                                <div key={index}
                                    onClick={() => setSlide(index)}
                                    className={`page-indicator me-3 me-md-3 cursor-pointer rounded-circle ${index === currentSlideIndex ? "active" : ""}`} />
                            ))}
                            <div className={`page-indicator rounded-circle ${currentSlideIndex === slides.length ? "active" : ""}`} onClick={() => setSlide(slides.length)}
                            />
                        </div>
                    </div>
                    {/* Navigation Page number */}
                    <div className="d-flex d-md-none order-1 order-md-1 justify-content-center align-items-center">
                        <div className="d-flex justify-content-between bg-light p-3 px-4"
                            style={{ borderRadius: "2rem" }}
                        >
                            <div className="text-center fw-bold">
                                {currentSlideIndex + 1} • {slides.length + 1}
                            </div>
                        </div>
                    </div>
                    <div className="order-2 order-md-2 d-flex justify-content-between">
{/*                         {!endSlide && <button onClick={() => prevSlide()} className={"btn btn-secondary me-2 rounded-circle SlidePrev " + (currentSlideIndex === 0 ? "bg-secondary-subtle" : "")} disabled={currentSlideIndex === 0} >
                            <i className="fas fa-angle-left fa-2x" />
                        </button>} */}
                        {!endSlide && <button onClick={() => prevSlide()} className={"btn btn-secondary me-2 rounded-circle SlidePrev " + (currentSlideIndex === 0 ? "bg-secondary-subtle" : "")} disabled={currentSlideIndex === 0} style={{ width: "56px", height: "56px" }}>
                            <i className="fas fa-angle-left fa-2x" />
                        </button>}
                        {endSlide &&
                            <button className="btn btn-outline-primary my-auto bg-light fw-bold rounded-pill me-2" onClick={() => prevSlide()}>
                                {t('eduquiz:review')}
                            </button>
                        }
                        {!endSlide && <button onClick={() => nextSlide()} className="btn btn-primary rounded-circle SlideNext" disabled={currentSlideIndex === slides.length} style={{ width: "56px", height: "56px" }}>
                            <i className="fas fa-angle-right fa-2x" />
                        </button>}
                        {endSlide &&
                            <button className="btn btn-primary rounded-pill my-auto fw-bold text-truncate" onClick={() => startGame()}>
                                {t('eduquiz:lets_go')}
                            </button>}
                    </div>

                </div>
            </div>}
        </section >
    );
}