import Lottie from "lottie-react"
import React from "react"
import checkAnimation from 'assets/lotti/check.json';
import { useTranslation } from "react-i18next";

function PasswordVerifier({ password, confirmPassword }: { password: string, confirmPassword: string }) {
    const { t } = useTranslation(['password'])
    return (
        <small className='mb-3'>
            <span>{t('password:must')} </span><br />
            <div className="d-flex align-items-center">
                <span>
                    {t('password:uppercase')}</span>
                {((RegExp(/.*[A-Z].*/).test(password)) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                    <i className="fas fa-times-circle text-danger" />
                </div>}
            </div>
            <div className="d-flex align-items-center">
                <span>
                    {t('password:lowercase')}</span>
                {((RegExp(/.*[a-z].*/).test(password)) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                    <i className="fas fa-times-circle text-danger" />
                </div>}
            </div>
            <div className="d-flex align-items-center">

                <span>
                    {t('password:number')}</span>
                {((RegExp(/.*\d.*/).test(password)) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                    <i className="fas fa-times-circle text-danger" />
                </div>}
            </div>
            <div className="d-flex align-items-center">

                <span>
                    {t('password:special')}</span>
                {((RegExp(/(?=.*[\W_])/).test(password)) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                    <i className="fas fa-times-circle text-danger" />
                </div>}
            </div>
            <div className="d-flex align-items-center">
                <span>
                    {t('password:min_length', {
                        min: 8
                    })}</span>
                {((password && password.length >= 8) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                    <i className="fas fa-times-circle text-danger" />
                </div>}
            </div>
            <div className="d-flex align-items-center">

                <span>{t('password:match')}</span>
                {((password === confirmPassword && password && confirmPassword) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                    <i className="fas fa-times-circle text-danger" />
                </div>}
            </div>
        </small>
    )
}
export default PasswordVerifier