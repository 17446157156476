import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import QuizProgressIcon from 'components/QuizProgressIcon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEduquizManagerStore } from 'stores/eduquizManagerStore';
import { useI18nStore } from 'stores/i18nStore';
import { EditArgumentModal } from './EduQuizModals';
import toast from 'react-hot-toast';
import { useCompanyStore } from 'stores/companyStore';
import { Modal, Table } from 'react-bootstrap';
import { useCompanyManagerStore } from 'stores/companyManagerStore';
import { QuizArgument } from 'models/eduquizModels';
import Config, { appThemes } from 'utils/config';
import { useTitle } from 'utils/functions';
import { useLearningMaterialsManagerStore } from 'stores/learningMaterialsManagerStore';
import LearningMaterialsManagerPage from 'pages/learning_materials/LearningMaterialsManagerPage';
import AppThemed from 'components/AppThemed';
import HeaderWidgetPortal from 'components/HeaderWidgetPortal';
import { useAuthStore } from 'stores/authStore';
import Mark from 'components/Mark';
import { routes } from 'Routes';

/**
 * Main quizpage component.
 * This component is used to show the main page of the eduquiz.
 * It shows a list of arguments.
 * It also contains a modal to create a new argument.
 */
function EduQuizManagerPage({ companyId }: { companyId: string | null }) {
    // We use the eduquiz store to get the argument list
    const argumentList = useEduquizManagerStore((state) => state.argumentList);
    const initEduquiz = useEduquizManagerStore((state) => state.initEduquiz);
    const { t } = useTranslation();
    const [showArgumentModal, setShowArgumentModal] = React.useState(false);
    const [filter, setFilter] = React.useState("");
/*     const [firstInit, setFirstInit] = React.useState(true);
 */
    const getTranslation = useI18nStore((state) => state.getTranslation);
    useTitle(t(Config.appTheme === appThemes.SCHOOL ? 'eduquiz_manager:school.title' : 'eduquiz_manager:title'));
    // This effect is used to initialize the eduquiz
    useEffect(() => {
        console.log('� EduQuizPage: useEffect');
        if ((argumentList === null || (
            (Config.appTheme !== appThemes.CLASSIC &&
                useEduquizManagerStore.getState().eduQuizCompanyId !== companyId && companyId)
        )) /* && firstInit */) {
            console.log('� EduQuizPage: useEffect: initArgumentList');
            initEduquiz(companyId ?? useCompanyStore.getState().currentCompany?.companyId ?? "");
        }
    }, [argumentList, companyId, initEduquiz]);

    const handleFilter = (argument: QuizArgument) => {
        if (filter === "") return true;
        return getTranslation(argument.argumentDetail.title).toLowerCase().includes(filter.toLowerCase());
    }
    // If the eduquiz is not initialized yet, we show a loading indicator
    if (argumentList === null || argumentList === undefined) return (<Loader />);
    // If the eduquiz is initialized, we show the eduquiz
    return (
        <section className='EduQuizPage d-flex flex-column'>
            <HeaderWidgetPortal>
                <CircleButton className='ms-auto' iconClassName={"fas fa-plus"}
                    onClick={() => setShowArgumentModal(true)}
                />
            </HeaderWidgetPortal>
            <AppThemed themes={[appThemes.CLASSIC]}>
                <h4 className='fw-bold mb-3'>{t('eduquiz_manager:title')}</h4>
            </AppThemed>

            <AppThemed themes={[appThemes.SCHOOL]}>
                <div className='d-flex flex-column justify-content-center mb-3'>
                    <h5 className='fw-normal mb-3'>{t('eduquiz_manager:school.subtitle')}</h5>
                    <div className="input-group" style={{ maxWidth: "300px" }}>
                        <span className="input-group-text">
                            <i className="fas fa-search"></i>
                        </span>
                        <input
                            type="text"
                            className="form-control bg-body"
                            placeholder={t('common:search')}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </div>
                </div>
            </AppThemed>
            <AppThemed themes={[appThemes.SIDEBARS, appThemes.CLASSIC]}>

                <div className=' mb-4 card'>
                    <div className="card-body d-flex flex-column">
                        <ul className="list-group list-group-flush">
                            {argumentList.map((quizArgument) => <EduQuizArgumentListTile key={quizArgument.argumentId} quizArgument={quizArgument} />)}
                        </ul>
                    </div>
                </div>
            </AppThemed>
            <AppThemed themes={[appThemes.SCHOOL]}>
                <Table hover>
                    <thead>
                        <tr>
                            <th className="fw-normal text-uppercase small">
                                {t('common:arguments')}
                            </th>

                            <th className="fw-normal text-uppercase small" style={{ width: "50px" }}>
                                <Mark>
                                    {t('common:modules')}
                                </Mark>
                            </th>
                            <th className="fw-normal text-uppercase small" style={{ width: "50px" }}>
                                <Mark>
                                    {t('common:units')}
                                </Mark>
                            </th>
                            <th className="fw-normal text-uppercase small" style={{ width: "50px" }}>
                                <Mark>
                                    {t('common:slides')}
                                </Mark>
                            </th>
                            <th className="fw-normal text-uppercase small justify-content-end d-flex">Data Update</th>
                            <th className="fw-normal text-uppercase small"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {argumentList.filter((argument) => handleFilter(argument)).map((quizArgument) => <EduQuizArgumentTableRow key={quizArgument.argumentId} quizArgument={quizArgument} />)}
                    </tbody>
                </Table>
            </AppThemed>
            <EditArgumentModal isCreating={true} onHide={() => setShowArgumentModal(false)} show={showArgumentModal} />
        </section>
    )
}
/* -------------------------------------------------------------------------- */
/*                             ARGUMENT LIST TILE                             */
/* -------------------------------------------------------------------------- */
function EduQuizArgumentListTile(props: { quizArgument: QuizArgument }) {
    const quizArgument = props.quizArgument;
    const navigate = useNavigate();
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const { t } = useTranslation();
    const deleteArgument = useEduquizManagerStore((state) => state.deleteArgument);
    const companies = useCompanyManagerStore((state) => state.companyList);
    const [selectedCompanyForCopy, setSelectedCompanyForCopy] = React.useState<string | null>(null);
    useEffect(() => {
        if (companies === null) {
            useCompanyManagerStore.getState().init();
        }
    }, [companies]);
    const [showCopyModal, setShowCopyModal] = React.useState(false);
    let color = quizArgument.argumentDetail.color;
    // If no color is provided, default to red.
    if (!color) {
        color = 'red';
    } else {
        color = color.replace('0xFF', '#');
    }
    const handleDelete = (e: any) => {
        e.stopPropagation();
        if (window.confirm(t('eduquiz_manager:delete_argument_confirm'))) {
            toast.promise(deleteArgument(quizArgument), {
                loading: t('common:deleting'),
                success: t('common:deleted'),
                error: t('common:error')
            });
        }
    }
    const handleCopyRequest = (e: any) => {
        e.stopPropagation();
        setShowCopyModal(true);
    }
    const handleCopy = (e: any) => {
        e.stopPropagation();
        setShowCopyModal(false);
        toast.promise(useEduquizManagerStore.getState().copyArgument(quizArgument.argumentId, selectedCompanyForCopy!), {
            loading: t('common:loading'),
            success: t('common:done'),
            error: t('common:error')
        });
    }
    return (
        <>
            <li className="EduQuizArgumentListTile hoverable list-group-item d-flex flex-row align-items-center cursor-pointer text-hoverable ps-0" onClick={() => {
                navigate(routes.arguments(quizArgument.argumentId));
            }}>
                <QuizProgressIcon percentage={100} icon={quizArgument.argumentDetail?.fontIcon ?? ""} iconUrl={quizArgument.argumentDetail.iconId} color={color} width={4} />
                <h6 className='ms-3 mb-0' style={{ width: "30%" }}>
                    {getTranslation(quizArgument.argumentDetail.title)}
                </h6>
                <div className='d-flex align-items-center text-muted mx-auto'>
                    <h6 className='mb-0 me-2 '>
                        {t('common:modules')}
                    </h6>
                    <span className='badge bg-primary me-4 '>
                        {(quizArgument.argumentDetail.modules ?? []).length}
                    </span>
                    <h6 className='mb-0 me-2 '>
                        {t('common:units')}
                    </h6>
                    <span className='badge bg-primary me-4'>
                        {(quizArgument.argumentDetail.modules ?? []).reduce((total, quizmodule) => total + (quizmodule.moduleDetail.units ?? []).length, 0)}
                    </span>
                    <h6 className='mb-0 me-2 '>
                        {t('common:slides')}
                    </h6>
                    <span className='badge bg-primary me-4'>
                        {(quizArgument.argumentDetail.modules ?? []).reduce((total, quizmodule) => total + (quizmodule.moduleDetail.units ?? []).reduce((total, unit) => total + (unit.unitDetail.slides ?? []).length, 0), 0)}
                    </span>
                </div>
                <div className='ms-auto d-flex align-items-center'>
                    {
                        useAuthStore.getState().isSystem && <>
                            <CircleButton className='me-3' iconClassName={"fas fa-copy"} variant='primary' onClick={handleCopyRequest} />
                            <CircleButton className='me-3' iconClassName={"fas fa-trash"} variant='danger' onClick={handleDelete} />
                        </>
                    }
                    <i className='fal fa-angle-right'></i>
                </div>
            </li>
            <Modal
                show={showCopyModal}
                onHide={() => {
                    setSelectedCompanyForCopy(null);
                    setShowCopyModal(false)
                }}
                centered
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>{t('eduquiz_manager:copy_argument')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('eduquiz_manager:copy_argument_text')}</p>
                    <ul className="list-group">
                        {
                            companies && companies.filter((company) => !company.companyDetail.isDefault && company.companyId !== quizArgument.argumentDetail.companyId)
                                .map(
                                    (company) => (
                                        <li className="list-group-item d-flex" key={company.companyId}>
                                            <input className="form-check-input" type='radio' name='company' value={company.companyId} id={company.companyId} checked={selectedCompanyForCopy === company.companyId} onChange={(e) => setSelectedCompanyForCopy(e.target.value)} />
                                            <label className='ms-2 flex-fill' htmlFor={company.companyId} >{company.companyDetail.company}</label>
                                        </li>
                                    )
                                )
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={() => setShowCopyModal(false)}>{t('common:cancel')}</button>
                    <button className='btn btn-primary'
                        onClick={handleCopy}
                        disabled={selectedCompanyForCopy === null}
                    >{t('common:copy')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

/* -------------------------------------------------------------------------- */
/*                           ARGUMENT ROW FOR TABLE                            */
/* -------------------------------------------------------------------------- */
function EduQuizArgumentTableRow(props: { quizArgument: QuizArgument }) {
    const quizArgument = props.quizArgument;
    const navigate = useNavigate();
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const { t } = useTranslation();
    const deleteArgument = useEduquizManagerStore((state) => state.deleteArgument);
    const companies = useCompanyManagerStore((state) => state.companyList);
    const [selectedCompanyForCopy, setSelectedCompanyForCopy] = React.useState<string | null>(null);
    useEffect(() => {
        if (companies === null) {
            useCompanyManagerStore.getState().init();
        }
    }, [companies]);
    const [showCopyModal, setShowCopyModal] = React.useState(false);
    let color = quizArgument.argumentDetail.color;
    // If no color is provided, default to red.
    if (!color) {
        color = 'red';
    } else {
        color = color.replace('0xFF', '#');
    }
    const handleDelete = (e: any) => {
        e.stopPropagation();
        if (window.confirm(t('eduquiz_manager:delete_argument_confirm'))) {
            toast.promise(deleteArgument(quizArgument), {
                loading: t('common:deleting'),
                success: t('common:deleted'),
                error: t('common:error')
            });
        }
    }
    const handleCopyRequest = (e: any) => {
        e.stopPropagation();
        setShowCopyModal(true);
    }
    const handleCopy = (e: any) => {
        e.stopPropagation();
        setShowCopyModal(false);
        toast.promise(useEduquizManagerStore.getState().copyArgument(quizArgument.argumentId, selectedCompanyForCopy!), {
            loading: t('common:loading'),
            success: t('common:done'),
            error: t('common:error')
        });
    }
    return (
        <>
            <tr className="EduQuizArgumentListTile hoverable cursor-pointer text-hoverable" onClick={() => {
                navigate(routes.arguments(quizArgument.argumentId));
            }}>
                {/*                 <td>
                    <QuizProgressIcon percentage={100} icon={quizArgument.argumentDetail?.fontIcon ?? ""} iconUrl={quizArgument.argumentDetail.iconId} color={color} width={4} />
                </td> */}
                <td style={{ width: "30%" }}>
                    <div >
                        <h6 className='mb-0 badge w-auto' style={{
                            backgroundColor: color
                        }}>
                            {getTranslation(quizArgument.argumentDetail.title)}
                        </h6>
                    </div>
                </td>
                <td className=''>
                    {(quizArgument.argumentDetail.modules ?? []).length}
                </td>
                <td className=''>
                    {(quizArgument.argumentDetail.modules ?? []).reduce((total, quizmodule) => total + (quizmodule.moduleDetail.units ?? []).length, 0)}
                </td>
                <td className=''>
                    {(quizArgument.argumentDetail.modules ?? []).reduce((total, quizmodule) => total + (quizmodule.moduleDetail.units ?? []).reduce((total, unit) => total + (unit.unitDetail.slides ?? []).length, 0), 0)}
                </td>
                <td className='justify-content-end d-flex'>
                    {new Date(quizArgument.argumentDetail.dateLastUpdate ?? "").toLocaleDateString()}
                </td>
                <td className='text-end'>
                    {
                        useAuthStore.getState().isSystem && <>
                            <CircleButton className='me-3' iconClassName={"fas fa-copy"} variant='primary' onClick={handleCopyRequest} />
                            <CircleButton className='me-3' iconClassName={"fas fa-trash"} variant='danger' onClick={handleDelete} />
                        </>
                    }
                    <i className='fal fa-angle-right'></i>
                </td>
            </tr>
            <Modal
                show={showCopyModal}
                onHide={() => {
                    setSelectedCompanyForCopy(null);
                    setShowCopyModal(false)
                }}
                centered
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>{t('eduquiz_manager:copy_argument')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('eduquiz_manager:copy_argument_text')}</p>
                    <ul className="list-group">
                        {
                            companies && companies.filter((company) => !company.companyDetail.isDefault && company.companyId !== quizArgument.argumentDetail.companyId)
                                .map(
                                    (company) => (
                                        <li className="list-group-item d-flex" key={company.companyId}>
                                            <input className="form-check-input" type='radio' name='company' value={company.companyId} id={company.companyId} checked={selectedCompanyForCopy === company.companyId} onChange={(e) => setSelectedCompanyForCopy(e.target.value)} />
                                            <label className='ms-2 flex-fill' htmlFor={company.companyId} >{company.companyDetail.company}</label>
                                        </li>
                                    )
                                )
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={() => setShowCopyModal(false)}>{t('common:cancel')}</button>
                    <button className='btn btn-primary'
                        onClick={handleCopy}
                        disabled={selectedCompanyForCopy === null}
                    >{t('common:copy')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EduQuizManagerPage
