import { Order } from "models/orderModel";
import { ApiService } from "services/apiService";
import Config from "utils/config";
import { create } from "./createStore";

interface OrderStoreState {
    orders: Order[] | null;
    fetchOrders: () => Promise<void>;
    createOrder: (order: Order) => Promise<Order>;
    changeOrderStatus: (order: Order, status: 'OPENED' | 'PENDING' | 'PAID' | 'CLOSED' | 'CANCELED') => Promise<void>;
    deleteOrder: (order: Order) => Promise<void>;
    hasPaidOrder: boolean;
    hasFreeOrderForExpert: (expertId: string) => boolean;
    remainingFreeBookingsForExpert: (expertId: string) => number;
}


export const useOrderStore = create<OrderStoreState>()((set, get, __) => ({
    orders: null,
    hasPaidOrder: false,
    hasFreeOrderForExpert: (expertId: string) => {
        return !(get().orders?.some(order => order.orderDetail.type === 'FREE'
            && order.orderDetail.targetIds.some(target => target.type === 'EXPERT' && target.value === expertId)
            && order.orderDetail.status === 'CLOSED') ?? false);
    },
    remainingFreeBookingsForExpert: (expertId: string) => {
        const max_free_bookings = Config.max_free_bookings;
        return max_free_bookings - (get().orders?.filter(order => order.orderDetail.type === 'FREE'
            && order.orderDetail.targetIds.some(target => target.type === 'EXPERT' && target.value === expertId)
            && order.orderDetail.status === 'CLOSED').length ?? 0) ?? max_free_bookings;
    },
    fetchOrders: async () => {
        try {
            const response = await ApiService.orders.search();
            console.log(response);
            set({ orders: response ?? [] });
        } catch (error) {
            console.error('Failed to fetch orders', error);
        }
    },
    createOrder: async (order: Order) => {
        try {
            const response = await ApiService.orders.create(order);
            set({ orders: [...get().orders!, response] });
            return response;
        } catch (error) {
            throw new Error('Failed to create order');
        }
    },
    changeOrderStatus: async (order: Order, status: 'OPENED' | 'PENDING' | 'CLOSED' | 'CANCELED' | 'PAID') => {
        try {
            await ApiService.orders.update({ ...order, orderDetail: { ...order.orderDetail, status } });
            set({
                orders: get().orders!.map(o => {
                    if (o.orderId === order.orderId) {
                        return { ...o, orderDetail: { ...o.orderDetail, status } };
                    }
                    return o;
                })
            });
        } catch (error) {
            console.error('Failed to change order status', error);
        }
    },
    deleteOrder: async (order: Order) => {
        try {
            await ApiService.orders.delete(order.orderId!);
            set({ orders: get().orders!.filter(o => o.orderId !== order.orderId) });
        } catch (error) {
            console.error('Failed to delete order', error);
        }
    }
}));