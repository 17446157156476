
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import cloneDeep from "clone-deep";
import { useI18nStore } from "./i18nStore";
import { UserProfile } from "models/userModels";
import { useEduquizStore } from "./eduquizStore";
import { useCompanyStore } from "./companyStore";
import { UserArgument, UserModule, UserQuestion, UserUnit } from "models/eduquizModels";
interface UserStoreState {
    user: UserProfile | null;
    eduquizStatistics: UserProgressionData | null;
    initUser: (userAuthProfileId: string) => Promise<UserProfile | null>;
    getUserQuizStatistics: (userId?: string) => Promise<UserProgressionData | null>;
    editUserProfileDetail: (userProfileDetail: any) => Promise<void>;
}
export interface UserProgressionData {
    argumentsCompleted: number;
    modulesCompleted: number;
    unitsCompleted: number;
    argumentsTotal?: number;
    modulesTotal?: number;
    unitsTotal?: number;
    averageScore?: number;
    userArguments: UserArgument[] | null;
    userModules: UserModule[] | null;
    userUnits: UserUnit[] | null;
    userQuestions: UserQuestion[] | null;
    correctQuestions: number;
}
export const useUserStore = create<UserStoreState>()((set, get, __) => ({
    user: null,
    eduquizStatistics: null,
    initUser: async (userAuthProfileId) => {
        try {
            let userResponse = await ApiService.user.getUserByAuthProfileId(userAuthProfileId);
            if (userResponse !== null) {
                set({ user: userResponse });
                if (!userResponse.userProfileDetail.locale) {
                    get().editUserProfileDetail({ locale: useI18nStore.getState().currentLanguage || 'en' });
                }
                if (userResponse.userProfileDetail.expertMeets === null || userResponse.userProfileDetail.expertMeets === undefined) {
                    get().editUserProfileDetail({ expertMeets: 1 });
                }
                if (userResponse.userProfileDetail.companyId) {
                    await useCompanyStore.getState().initCompanyStore(userResponse.userProfileDetail.companyId);
                }
                return userResponse;
            } else {
                return null;
            }
        } catch (e) {
            console.log('Error fetching user', e);
            throw e;
        }
    },
    getUserQuizStatistics: async (userId?: string) => {
        try {
            if (useEduquizStore.getState().argumentList === null) {
                await useEduquizStore.getState().initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? "");
            }

            let userArguments = await ApiService.education.getUserArgumentsHistory(userId);
            let userModules = await ApiService.education.getUserModulesHistory(userId);
            let userUnits = await ApiService.education.getUserUnitsHistory(userId);
            let userQuestions = await ApiService.education.getUserQuestionsHistory(userId);


            if (!userArguments) {
                return null;
            }
            // loop through the arguments 
            let argumentsCompleted = 0;
            let modulesCompleted = 0;
            let unitsCompleted = 0;
            let averageScore = 0;
            let correctQuestions = 0;

            for (let i = 0; i < userArguments.length; i++) {
                if (userArguments[i].userArgumentDetail.percentageCompletion === 100) {
                    argumentsCompleted++;
                    // find the related argument
                    /*                     const argument = (useEduquizStore.getState().argumentList ?? []).find(arg => arg.argumentId === userArguments[i].userArgumentDetail.argumentId);
                                        // log the title
                                        console.log(argument?.argumentDetail?.title.it); */
                }
                /*   for (let j = 0; j < (quizArguments[i].argumentDetail.modules ?? []).length; j++) {
                      if ((quizArguments[i].argumentDetail.modules ?? [])[j]!.moduleDetail.userModule!.userModuleDetail.percentageCompletion === 100) {
                          modulesCompleted++;
                      }
                      for (let k = 0; k < (quizArguments[i].argumentDetail.modules ?? [])[j]!.moduleDetail.units!.length; k++) {
                          if ((quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k]!.unitDetail.userUnit!.userUnitDetail.percentageCompletion === 100) {
                              unitsCompleted++;
                          }
                          if ((quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k]) {
                              const unit = (quizArguments[i].argumentDetail.modules![j]!.moduleDetail.units ?? [])[k];
                              const userUnit = unit!.unitDetail.userUnit;
                              const totalQuestions = unit?.unitDetail.questions?.length;
                              const correctQuestions = userUnit?.userUnitDetail.questionsCorrect.length;
                              if (totalQuestions && correctQuestions) {
                                  averageScore += correctQuestions / totalQuestions;
                              }
                          }
                      }
                  } */
            }
            for (let i = 0; i < userModules.length; i++) {
                if (userModules[i].userModuleDetail.percentageCompletion === 100) {
                    modulesCompleted++;
                    // find the related module
                    /*                     const module = (useEduquizStore.getState().argumentList ?? [])
                                            .flatMap(arg => arg.argumentDetail.modules ?? [])
                                            .find(mod => mod.moduleId === userModules[i].userModuleDetail.moduleId);
                                        // log the title
                                        console.log(module?.moduleDetail?.title.it); */
                }
            }
            for (let i = 0; i < userUnits.length; i++) {
                if (userUnits[i].userUnitDetail.percentageCompletion === 100) {
                    unitsCompleted++;
                    // find the related unit
/*                     const unit = (useEduquizStore.getState().argumentList ?? [])
                        .flatMap(arg => arg.argumentDetail.modules ?? [])
                        .flatMap(mod => mod.moduleDetail.units ?? [])
                        .find(unit => unit.unitId === userUnits[i].userUnitDetail.unitId); */
                    // log the title
/*                     console.log(unit?.unitDetail?.title.it);
 */                }
            }
            for (let i = 0; i < (userQuestions ?? []).length; i++) {
                if (userQuestions[i].userQuestionDetail.answeredOnceCorrectly) {
                    correctQuestions++;
                }
            }
            const totalQuestions = (useEduquizStore.getState().argumentList ?? []).reduce((acc, arg) => acc + (arg.argumentDetail.modules ?? []).reduce((acc, mod) => acc + (mod.moduleDetail.units ?? []).reduce((acc, unit) => acc + (unit.unitDetail.questions ?? []).length, 0), 0), 0);
            averageScore = totalQuestions ? correctQuestions / totalQuestions : 0;

            set({
                eduquizStatistics: {
                    argumentsCompleted: argumentsCompleted,
                    modulesCompleted: modulesCompleted,
                    unitsCompleted: unitsCompleted,
                    argumentsTotal: (useEduquizStore.getState().argumentList ?? []).length,
                    modulesTotal: (useEduquizStore.getState().argumentList ?? []).reduce((acc, arg) => acc + (arg.argumentDetail.modules?.length ?? 0), 0),
                    unitsTotal: (useEduquizStore.getState().argumentList ?? []).reduce((acc, arg) => acc + (arg.argumentDetail.modules?.reduce((acc, mod) => acc + (mod.moduleDetail.units?.length ?? 0), 0) ?? 0), 0),
                    averageScore: averageScore,
                    userArguments: userArguments,
                    userModules: userModules,
                    userUnits: userUnits,
                    userQuestions: userQuestions,
                    correctQuestions: correctQuestions,
                }
            });
            return get().eduquizStatistics as UserProgressionData;
        }
        catch (e) {
            console.error('Error getting user quiz statistics', e);
            return null;
        }
    },
    editUserProfileDetail: async (userProfileDetail) => {
        try {
            let newUser = get().user;
            if (newUser === null) {
                return;
            }
            newUser.userProfileDetail = { ...newUser.userProfileDetail, ...userProfileDetail };

            await ApiService.user.putUser(newUser);
            set({ user: cloneDeep(newUser) });
        } catch (e) {
            console.error('Error editing user profile detail', e);
        }
    }
}));
