import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export interface IAnimatedTextProps {
    delay: number;
    strings: string[];
    i18nPrefix: string;
    repeat?: number;
}

export default function AnimatedText({ delay, strings, i18nPrefix, repeat = Infinity }: IAnimatedTextProps) {
    const textIndex = useMotionValue(0);
    const { t } = useTranslation();

    const baseText = useTransform(textIndex, (latest) => t(i18nPrefix + strings[latest]) || "");
    const count = useMotionValue(0);
    const rounded = useTransform(count, (latest) => Math.round(latest));
    const displayText = useTransform(rounded, (latest) =>
        baseText.get().slice(0, latest)
    );
    const updatedThisRound = useMotionValue(true);

    useEffect(() => {
        animate(count, 350, {
            type: "tween",
            delay: delay,
            duration: 1.2,
            ease: "easeIn",
            repeat: repeat,
            repeatType: "reverse",
            repeatDelay: 1,
            onUpdate(latest) {
                if (updatedThisRound.get() === true && latest > 0) {
                    updatedThisRound.set(false);
                } else if (updatedThisRound.get() === false && latest === 0) {
                    if (textIndex.get() === strings.length - 1) {
                        textIndex.set(0);
                    } else {
                        textIndex.set(textIndex.get() + 1);
                    }
                    updatedThisRound.set(true);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <motion.span className="inline">{displayText}</motion.span>;
}
