import Loader from 'components/Loader';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Config from 'utils/config';
import { AuthStates, useAuthStore } from '../../stores/authStore';

function Splash() {
    const authState = useAuthStore((state) => state.authenticationState);
    const isAdmin = useAuthStore((state) => state.isAdmin);
    const isSystem = useAuthStore((state) => state.isSystem);
    switch (authState) {
        case AuthStates.Authenticated:
            if (isAdmin)
                return <Navigate to="/admin/dashboard" />;
            else
                if (isSystem)
                    return <Navigate to="/system/dashboard" />;
            if (useAuthStore.getState().isHR)
                return <Navigate to="/hr/dashboard" />;
            if (useAuthStore.getState().isAdmin)
                return <Navigate to="/admin/dashboard" />;
            else
                return <Navigate to={Config.userHomepagePath} />;
        case AuthStates.Unauthenticated:
        case AuthStates.AuthenticationExpired:
        case AuthStates.AuthenticationFailed:
        case AuthStates.Unauthorized:
            return <Navigate to="/login" />;
        case AuthStates.Initial:
        default:
            return (
                <Loader />
            );
    }
}

export default Splash;