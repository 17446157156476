import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useI18nStore } from 'stores/i18nStore';
import { useTranslation } from 'react-i18next';
import { useExpertsStore } from 'stores/expertsStore';
import StringParser from 'components/StringParser';
import { AmplitudeService } from 'services/amplitudeService';
import { useTitle } from 'utils/functions';
import CircleButton from 'components/CircleButton';
import SecondaryHeader from 'components/SecondaryHeader';
import { useUserStore } from 'stores/userStore';
import { InlineWidget, PopupButton } from "react-calendly";
import { useOrderStore } from 'stores/orderStore';
import { useCompanyStore } from 'stores/companyStore';
import { Expert } from 'models/expertModel';
import { Modal } from 'react-bootstrap';
import { useCalendlyEventListener } from "react-calendly";
import { on } from 'events';
import { Order } from 'models/orderModel';
import toast from 'react-hot-toast';
import Config from 'utils/config';
import { loadStripe } from '@stripe/stripe-js';
import { use } from 'i18next';
import { routes } from 'Routes';

function ExpertBookingHubspot({ url }: { url: string }) {
    const navigate = useNavigate();
    let { expertId } = useParams();
    const expertsList = useExpertsStore(state => state.expertsList);

    const { t } = useTranslation();
    const userDetail = useUserStore(state => state.user?.userProfileDetail);
    const getTranslation = useI18nStore(state => state.getTranslation);
    useTitle(t('experts:title'));

    useEffect(() => {
        if (expertsList === null) {
            useExpertsStore.getState().initExpertsStore();
        }
    }, [expertsList]);

    const meetingsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => {
            if (meetingsContainerRef.current) {
                // Initialize the HubSpot Meetings embed script
                (window as any).HubSpotConversations && (window as any).HubSpotConversations.widget.load();
            }
        };
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when the component is unmounted
            document.body.removeChild(script);
        };
    }, []);


    if (!expertsList) { return (<Loader />); }

    const expert = expertsList.find(expert => expert.expertId === expertId);


    if (!expert) {
        return (<Loader />);
    }

    return (
        <div className={`ExpertBooking d-flex h-100 w-100`}>
            <div
                ref={meetingsContainerRef}
                className="meetings-iframe-container w-100 h-100"
                data-src={`${url}?embed=true&firstname=${encodeURIComponent(userDetail?.name || '')}&lastname=${encodeURIComponent(userDetail?.surname || '')}&email=${encodeURIComponent(userDetail?.email || '')}`}
            ></div>
        </div>
    )
}
function ExpertBookingCalendly({ url, expert, onExpertBookingCompleted }: { url: string, expert: Expert, onExpertBookingCompleted: (payload: any) => void }) {
    useCalendlyEventListener({
        onEventScheduled: (e) => onExpertBookingCompleted(e.data.payload),
    });
    return (
        <>
            <InlineWidget
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: false,
                    primaryColor: '1cafb9',
                }}
                styles={{
                    height: '100%'
                }}
                prefill={{
                    name: (useUserStore.getState().user?.userProfileDetail.name || '') + ' ' + (useUserStore.getState().user?.userProfileDetail.surname || ''),
                    email: useUserStore.getState().user?.userProfileDetail.email || '',
                }}
                url={url} />
        </>
    )
}




function ExpertBooking({ url, expert }: { url: string, expert: Expert }) {
    const [latestOrder, setLatestOrder] = React.useState<Order | null>(null);
    const orders = useOrderStore(state => state.orders);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        console.log(latestOrder, orders);
        if (!latestOrder && !orders) {
            console.log("Fetching orders");
            useOrderStore.getState().fetchOrders();
        }
        if (!latestOrder && orders) {

            //try to find order for the expert
            const order = orders.filter(order => (order.orderDetail.status !== "CLOSED" && order.orderDetail.status !== "CANCELED")).find(order => order.orderDetail.targetIds.some(target => target.value === expert.expertId));
            if (order) {
                console.log("Found order");
                if (order.orderDetail.status === "OPENED") {
                    useOrderStore.getState().changeOrderStatus(order, "PENDING").then(() => {
                        order.orderDetail.status = "PENDING";
                        setLatestOrder(order);
                    });
                } else {
                    setLatestOrder(order);
                }

            } else {
                console.log("Creating order");
                useOrderStore.getState().createOrder({
                    orderDetail: {
                        userProfileId: useUserStore.getState().user?.userProfileId ?? "",
                        status: "OPENED",
                        companyId: useCompanyStore.getState().currentCompany?.companyId ?? "",
                        targetIds: [{
                            value: expert.expertId,
                            type: "EXPERT"
                        }],
                        type:
                            useOrderStore.getState().remainingFreeBookingsForExpert(expert.expertId) > 0 ? "FREE" : "CHARGEABLE",
                    }
                }).then(order => {
                    useOrderStore.getState().changeOrderStatus(order, "PENDING").then(() => {
                        order.orderDetail.status = "PENDING";
                        setLatestOrder(order);
                    });
                });
            }
        }
        // try to find the latest order for the expert
        if (latestOrder && orders && (latestOrder.orderDetail.status === "OPENED" || latestOrder.orderDetail.status === "PENDING") && latestOrder.orderDetail.type === "CHARGEABLE") {
            window.location.href = `${Config.stripeUrl}?client_reference_id=${latestOrder.orderId!}`;
        }
    }, [latestOrder, expert, orders]);

    const onExpertBookingCompleted = (payload: any) => {
        if (!latestOrder) toast.error(t('experts:booking_error'));
        useOrderStore.getState().changeOrderStatus(latestOrder!, "CLOSED");
    }
    /* ORDER NOT READY */
    if (!latestOrder && !orders) {
        return <Loader />
    }
    /*     if (latestOrder && orders && latestOrder.orderDetail.status !== "CLOSED" && latestOrder.orderDetail.type === "CHARGEABLE") {
            return <Loader />;
        } */
    /* ORDER IS PAID OR FREE */
    if (latestOrder && orders && (latestOrder.orderDetail.status === "PAID" || latestOrder.orderDetail.type === "FREE")) {
        return (
            <>
                {url.includes('ciaoelsa') && <ExpertBookingHubspot url={url} />}
                {!url.includes('ciaoelsa') && <ExpertBookingCalendly url={url} expert={expert} onExpertBookingCompleted={onExpertBookingCompleted} />}
                {/* FIXED MENU */}
                <div className="position-fixed d-flex flex-column justify-content-end align-items-center 
            bottom-0 start-0 w-100" style={{ zIndex: "3" }}>
                    <div className={" card w-100 "}>
                        <div className="container-lg d-flex flex-row justify-content-center align-items-start card-body p-4">
                            <p className={"mb-3 fw-bold d-none"}></p>

                            {/* {logo && <img src={logo} alt="logo" className="mb-3" /> */}
                            {/*               {!expert.expertDetail.url.it.includes('ciaoelsa.com') &&
                            <PopupButton
                                url={getTranslation(expert.expertDetail.url) + "?primary_color=1cafb9"}
                                className={"btn btn-primary btn-block rounded-pill px-5 ms-auto"}
                                rootElement={document.getElementById("root")!}

                                prefill={{
                                    name: (useUserStore.getState().user?.userProfileDetail.name || '') + ' ' + (useUserStore.getState().user?.userProfileDetail.surname || ''),
                                    email: useUserStore.getState().user?.userProfileDetail.email || '',
                                }}
                                text={t('experts:book_now') + (remainingFreeBookings > 0 && " GRATIS")
                                } ></PopupButton>} */}
                            {/* {expert.expertDetail.url.it.includes('ciaoelsa.com') && <button onClick={() => {
                            AmplitudeService.logEvent('Expert Booking Started', { expertId: expert.expertId });
                            // open link in new tab
                            window.open(getTranslation(expert.expertDetail.url), '_blank');
                        }} className={"ms-auto btn btn-primary btn-block rounded-pill px-5 "}>{t('experts:book_now')}
                            {
                                remainingFreeBookings > 0 && "GRATIS"
                            }
                        </button>} */}
                            <button onClick={() => {
                                AmplitudeService.logEvent('Expert Booking Started', { expertId: expert.expertId });
                                // open link in new tab
                                /* window.open(getTranslation(expert.expertDetail.url), '_blank'); */
                                navigate(routes.expertDetail(expert.expertId));
                            }} className={"ms-auto btn btn-primary btn-block rounded-pill px-5 "}>{t(
                                'common:back'
                            )}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    console.log(latestOrder?.orderDetail.status, latestOrder?.orderDetail.type);
    return <Loader />;
}

export default ExpertBooking;

export function ExpertBookingPage() {
    const { expertId } = useParams();
    const expertsList = useExpertsStore(state => state.expertsList);
    const expert = expertsList?.find(expert => expert.expertId === expertId);
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const url = getTranslation(expert?.expertDetail.url) ?? expert?.expertDetail.url.it ?? "";
    useTitle(t('experts:book_now'));

    useEffect(() => {
        if (!expertsList) {
            useExpertsStore.getState().initExpertsStore();
        }
    }, [expertsList]);

    return (
        <section className={`ExpertDetail d-flex flex-column mb-5 pb-5 h-100`}>
            <SecondaryHeader title={
                t('experts:book_now')
            } /* backTarget=".." */ />
            {expert && <ExpertBooking url={url ?? ""} expert={expert} />}
            {!expert && <Loader />}

        </section>
    )
}