import CircleButton from 'components/CircleButton';
import Lottie from 'lottie-react';
import React, { useEffect } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'; import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { AuthStates, useAuthStore } from 'stores/authStore';
import Config from 'utils/config';
import checkAnimation from 'assets/lotti/check.json';
import { Modal, ModalBody } from 'react-bootstrap';
import PasswordVerifier from 'components/PasswordVerifier';

function ForgotPasswordDialog() {
    const { t } = useTranslation();

    const requestPasswordReset = useAuthStore(state => state.requestPasswordReset);
    const confirmPasswordReset = useAuthStore(state => state.confirmPasswordReset);

    const [usedEmail, setUsedEmail] = React.useState('');
    const [code, setCode] = React.useState('');
    const [formData, setFormData] = React.useState({ password: '', confirmPassword: '' });
    const [showEmailModal, setShowEmailModal] = React.useState(false);
    const [showConfirmationCodeModal, setShowConfirmationCodeModal] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const authState = useAuthStore(state => state.authenticationState);
    useEffect(() => {
        if (authState === AuthStates.ChangePasswordRequired) {
            setUsedEmail(useAuthStore.getState().userAuthEmail ?? "");
            setShowConfirmationCodeModal(true);
        }
    }, [authState]);

    const onConfirmationSubmit = (e: any) => {
        e.preventDefault();
        // get data from form
        const password = e.target.password.value;
        toast.promise(confirmPasswordReset(usedEmail, code, password), {
            loading: t('common:saving'),
            success: t('forgot:password_reset_success'),
            error: t('forgot:expired_code'),
        }).then(() => {
            setShowConfirmationCodeModal(false);
        }).finally(() => {
            setFormData({ password: '', confirmPassword: '' });
            setCode('');
        });
    }
    const onEmailSubmit = (e: any) => {
        e.preventDefault();
        toast.promise(requestPasswordReset(usedEmail), {
            loading: t('common:saving'),
            success: t('forgot:email_sent'),
            error: t('forgot:limit_exceeded'),
        }).then(() => {
            setShowEmailModal(false);
            setShowConfirmationCodeModal(true);
        }).catch(() => {
        });
    }
    return (
        <>
            <div className='text-center mt-3' onClick={() => setShowEmailModal(true)}>

                <span>
                    {t('forgot:forgot_question')}
                </span>
                <span className='text-primary fw-bold ms-2 text-decoration-underline cursor-pointer' data-bs-dismiss="modal" >
                    <div>{t('common:click_here')}</div>
                </span>
            </div>
            <Modal id="forgotPasswordModal" show={showEmailModal} onHide={() => setShowEmailModal(false)} aria-labelledby="forgotPasswordModalLabel" centered>
                <ModalBody className='p-5'>
                    <CircleButton onClick={() => setShowEmailModal(false)} className="me-2 mb-2" iconClassName="fas fa-arrow-left" />
                    <div className="d-flex justify-content-between">
                        <h1 className="" id="forgotPasswordModalLabel">{t('forgot:forgot_question')}</h1>
                    </div>
                    <p className='text-muted'>{t('forgot:explanation')}</p>
                    <form onSubmit={onEmailSubmit} name="Password dimenticata - inserimento email">
                        <div className="mb-4 form-floating" >
                            <input placeholder="E-mail" name="email" value={usedEmail} onChange={(e) => setUsedEmail(e.target.value)} type="email" className="form-control" id="email" required />
                            <label htmlFor="email" className="form-label">{t('forms:email')}</label>
                        </div>
                        <div className='d-none'></div>
                        <button disabled={usedEmail.length === 0 || !Config.emailRegex.test(usedEmail)} type="submit" className="btn btn-primary w-100 rounded-pill">{t('common:confirm')}</button>
                    </form>
                </ModalBody>
            </Modal>
            {/* bootstrap modal for confirmation code */}
            <Modal id="confirmationCodeModal" show={showConfirmationCodeModal} onHide={() => setShowConfirmationCodeModal(false)} aria-labelledby="confirmationCodeModalLabel" centered>



                <ModalBody className='p-5'>
                    <div className='mb-3' >
                        <CircleButton onClick={() => {
                            setShowConfirmationCodeModal(false);
                            setShowEmailModal(true);
                            setFormData({ password: '', confirmPassword: '' });
                            setCode('');
                        }} iconClassName="fas fa-arrow-left" />
                    </div>
                    <form className='d-flex flex-column' onSubmit={onConfirmationSubmit} key={'forgotPwCode'} name="Password dimenticata - conferma codice" >

                        <h3 className='mb-3 h3'>{t('forgot:reset_password')}</h3>
                        <p className='mb-4'>
                            <Trans i18nKey="forgot:email_sent_message" values={{ usedEmail }}>
                                {{ usedEmail }}
                            </Trans>
                        </p>
                        <div className="mb-4 form-floating d-flex align-items-center" >
                            <ReactInputVerificationCode
                                length={6}
                                onChange={(value) => {
                                    setCode(value)
                                }}
                                value={code}
                            />
                        </div>
                        <h3 className='mb-3'>{t('forgot:choose_password')}</h3>
                        <PasswordVerifier password={formData.password} confirmPassword={formData.confirmPassword} />
                        <div className="mb-4 form-floating" >
                            <input placeholder="Password" value={formData['password'] ?? ""} onChange={(event) => setFormData({ ...formData, password: event.target.value })} name="password"
                                type={showPassword ? "text" : "password"} className="form-control" id="password" required autoComplete='off' /* value={formData['password'] ?? ""} onChange={(event) => setFormData({ ...formData, password: event.target.value })} */ />

                            <label htmlFor="password" className="form-label">Password</label>
                            <div className="input-group-append position-absolute end-0 top-0 pt-2 me-2">
                                <button className="btn p-2 border-0 bg-transparent" type="button" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={`fas fa-fw fa-eye${showPassword ? "-slash" : ""}`}></i>
                                </button>
                            </div>
                        </div>
                        <div className="mb-4 form-floating" >
                            <input placeholder="Confirm password" value={formData['confirmPassword'] ?? ""}
                                onChange={(event) => setFormData({ ...formData, confirmPassword: event.target.value })}
                                name="confirmPassword" type={showPassword ? "text" : "password"} className="form-control" id="confirmPassword"
                            /* value={formData['confirmPassword'] ?? ""} required onChange={(event) => setFormData({ ...formData, confirmPassword: event.target.value })} */ />

                            <label htmlFor="confirmPassword" className="form-label">{t('common:confirm')}</label>
                            <div className="input-group-append position-absolute end-0 top-0 pt-2 me-2">
                                <button className="btn p-2 border-0 bg-transparent" type="button" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={`fas fa-fw fa-eye${showPassword ? "-slash" : ""}`}></i>
                                </button>
                            </div>
                        </div>
                        <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill" disabled={code.length !== 6}  >{t('common:confirm')}</button>
                    </form>
                </ModalBody>

            </Modal>
        </>
    )
}

export default ForgotPasswordDialog