import { Company } from "models/companyModel";
import Config from "utils/config";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useI18nStore } from "./i18nStore";
import { useUserStore } from "./userStore";
import { useAuthStore } from "./authStore";

let isInitRunning = false;

export interface CompanyStore {
    currentCompany: Company | null;
    initCompanyStore: (companyId: string) => Promise<any | null>;
    /*     updateCompany: (company: Company) => Promise<any | null>; */
    applyCompanyConfig: (company: any) => Promise<void>;
}

export const useCompanyStore = create<CompanyStore>()((set, get, _) => ({
    currentCompany: null,
    initCompanyStore: async (companyId) => {
        if (isInitRunning) {
            return;
        }
        try {
            isInitRunning = true;
            let company = await ApiService.company.getCompany(companyId);
            if (company) {
                set({ currentCompany: company });
                await useI18nStore.getState().initI18nStore(company.companyDetail.languages);
                await get().applyCompanyConfig(company);
                console.log('Company initialized:', company);
                return company;
            } else {
                /* alert('Company not found.'); */
/*                 if (useAuthStore.getState().isSystem) {
                    console.log('Welcome aboard captain! All systems online.');
                } else {
                    console.log('Company not found. ');
                } */
                return null;
            }
        } catch (e) {
            console.log('Company not found.', e);
            throw e;
        } finally {
            isInitRunning = false;
        }
    },
    /** Apply the company configuration to the app
     * 
     */
    applyCompanyConfig: async (company) => {
        try {
            if (company) {
                const cd = company.companyDetail;
                Config.enabledFeatures = cd.enabledFeatures;
                Config.partnerLogo = cd.companyLogo;
                Config.partnerUrl = cd.multiLanguageUrl[useI18nStore.getState().currentLanguage || 'en'];
                // Custom variables
                const cssRule = `
                    :root {
                        ${cd.backGroundColor2 ? `--bs-primary: ${cd.backGroundColor2};` : ""}
                        ${cd.textColor1 ? `--bs-secondary: ${cd.textColor1};` : ""}
                        ${cd.backGroundColor1 ? `--bs-body-bg: ${cd.backGroundColor1};` : ""}
                    }
                    ${cd.callToActionColor}
                `;

                // Apply the CSS rule to the document
                const styleElement = document.createElement('style');
                styleElement.innerHTML = cssRule;
                document.head.appendChild(styleElement);
            }
        } catch (e) {
            console.log('ERROR: applyCompanyConfig', e);
        }
    }
}));

