import React, { useState, useMemo } from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useI18nStore } from "stores/i18nStore";
import { getFlagEmoji } from "utils/functions";

function LearningMaterialEditor(
  { learningMaterial, learningMaterialImage, learningMaterialFile, setLearningMaterialImage, setLearningMaterialFile, handleLearningMaterialChange }
    : {
      learningMaterial: any,
      learningMaterialImage: File | null,
      learningMaterialFile: File | null,
      setLearningMaterialImage: (file: File) => void,
      setLearningMaterialFile: (file: File) => void,
      handleLearningMaterialChange: (learningMaterial: any) => void,
    }
) {
  const { t } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState("it");
  const languages = useI18nStore((state) => state.quizLanguages);
  const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
  const description = useMemo(() => getTranslationWithLanguage(learningMaterial.description, activeLanguage), [learningMaterial.description, activeLanguage, getTranslationWithLanguage]);
  return (
    <div>
      <Form>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="learningMaterialTitle">{t('forms:name')}</FormLabel>
          <FormControl type="text" id="learningMaterialname" placeholder={t('forms:title')} name="name"
            value={learningMaterial.title}
            onChange={(e) => {
              /*               alert(e.target.value); */
              handleLearningMaterialChange({ ...learningMaterial, title: e.target.value })
            }} />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="learningMaterialUrl">URL</FormLabel>
          <FormControl type="text" id="learningMaterialUrl" placeholder={t('forms:url')} name={"url"}
            value={learningMaterial.url}
            onChange={(e) => handleLearningMaterialChange({
              ...learningMaterial,
              url: e.target.value,
            })} />
        </FormGroup>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {(languages ?? []).map((language: string) =>
              <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center " + (language === activeLanguage ? "active" : "")}
                onClick={() => setActiveLanguage(language)} type="button">
                <div>{language.toUpperCase()}</div>
                <div>{getFlagEmoji(language)}</div>
              </button>
            )}
          </div>
        </nav>
        <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
          {(languages ?? []).map((language: string) =>
            <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
              <div className="d-flex flex-column h-100">
                <FormGroup className="mb-3">
                  <FormLabel htmlFor="learningMaterialTitle">{t('forms:title')}</FormLabel>
                  <FormControl type="text" id="learningMaterialTitle" placeholder={t('forms:title')} name={"title_" + language}
                    value={getTranslationWithLanguage(learningMaterial.multiLanguageTitle, language)}
                    onChange={(e) => handleLearningMaterialChange({
                      ...learningMaterial,
                      multiLanguageTitle: {
                        ...learningMaterial.multiLanguageTitle,
                        [language]: e.target.value,
                      },
                    })} />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel htmlFor="learningMaterialDescription">{t('forms:description')}</FormLabel>
                  <FormControl as="textarea" id="learningMaterialDescription" placeholder={t('forms:description')} name={"description_" + language}
                    value={getTranslationWithLanguage(learningMaterial.description, language)}
                    onChange={(e) => handleLearningMaterialChange({
                      ...learningMaterial,
                      description: {
                        ...learningMaterial.description,
                        [language]: e.target.value,
                      },
                    })} />
                </FormGroup>
              </div>
            </div>)}
        </div>
        <Form.Group className="mb-3">
          <Form.Label>{t('common:category')}</Form.Label>
          <Form.Control
            as="select"
            value={learningMaterial.category}
            onChange={(e) => handleLearningMaterialChange({
              ...learningMaterial,
              category: e.target.value,
            })}
          >
            <option value="">{t('common:select_option')}</option>
            {[
              "Home Insurance",
              "Investment",
              "Financial Planning",
              "Budget Management",
              "Cost Saving",
              "Home Savings",
              "Corporate Welfare",
              "Mental Health",
              "Employee Benefits"
            ].map((category) => (
              <option key={category} value={t('learning_materials_categories:' + (category.replace(/\s+/g, '')))}>
                {category}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <label className="form-label">{t('learningMaterialsManager:learning_material_file')}</label>
          <div id="nav-lm-file" className="d-flex flex-column mb-3">
            {!(learningMaterial.storagePath || learningMaterialFile) && <i className="mb-2">{t('forms:messages.no_file_selected')}</i>}
            {((learningMaterial.storagePath || learningMaterialFile)) && <span className="mb-2">{learningMaterialFile ? learningMaterialFile.name : learningMaterial.file}</span>}
            {learningMaterial.storagePath}
            <input onChange={(event) => {
              const files = event.target.files;
              if (files != null && files[0].type.startsWith('application/')) {
                setLearningMaterialFile(files[0]);
              } else {
                // Handle invalid file type
              }
            }} className="form-control" type="file" id="formFile" accept="application/*" />

            {((learningMaterial.file || learningMaterialFile)) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

            }} value={learningMaterial.file ?? ""}>
              <i className='fas fa-trash' /> {t('common:delete')} File
            </button>}
          </div>
        </Form.Group>
        <Form.Group>
          <label className="form-label">{t('learningMaterialsManager:learning_material_image')}</label>
          <div id="nav-lm-image" className="d-flex flex-column mb-3">
            {!(learningMaterial.img || learningMaterialImage) && <i className="mb-2">{t('forms:messages.no_image_selected')}</i>}
            {((learningMaterial.img || learningMaterialImage)) && <img src={learningMaterialImage != null ? URL.createObjectURL(learningMaterialImage) : (learningMaterial.img ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}
            <input onChange={(event) => {
              const files = event.target.files;
              if (files != null && files[0].type.startsWith('image/')) {
                setLearningMaterialImage(files[0]);
              } else {
                // Handle invalid file type
                alert('Invalid file type');
              }
              console.log(learningMaterialImage);
            }} className="form-control" type="file" id="formFile" accept="image/*" required />

            {((learningMaterial.img || learningMaterialImage)) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

            }} value={learningMaterial.img ?? ""}>
              <i className='fas fa-trash' /> {t('common:delete')} Image
            </button>}
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

export default LearningMaterialEditor;
