import React from 'react';
import { Navigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { AuthStates, useAuthStore } from '../../../stores/authStore';
function LoginAdmin() {
    const authState = useAuthStore((state) => state.authenticationState);
    const doLogin = useAuthStore((state) => state.login);
    const onSubmit = (e) => {
        e.preventDefault();
        // get elements from the form
        const { email, password } = e.target.elements;
        doLogin(email.value, password.value);
    };
    if (authState === AuthStates.Authenticated) {
        return <Navigate to={'/dashboard'} />;
    }
    return (
        <div className='container vh-100'>
            <div className='row h-100'>
                <div className='col-12 col-md-6 mx-auto h-auto my-auto'>
                    <form onSubmit={onSubmit}>
                        <div className='card d-flex flex-column p-3'>
                            <div className='card-body'>
                                <div className='d-flex'>
                                    <img className='w-75 img-fluid mx-auto' src={logo} alt='logo'></img>
                                </div>
                                <h4 className='text-center'>Admin Backoffice</h4>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email address</label>
                                    <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input name="password" type="password" className="form-control" id="password" required />
                                </div>
                                {/*                                 <div className="mb-3 form-check">
                                    <input name="rememberMe" type="checkbox" className="form-check-input" id="exampleCheck1"  />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                                </div> */}
                            </div>
                            <div className='card-footer d-flex flex-row-reverse'>
                                <button type="submit" className="ml-auto btn btn-primary">Login</button>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-center w-100">
                        {authState === AuthStates.AuthenticationFailed && <div className="alert alert-danger position-fixed bottom-0 mx-auto" role="alert">
                            Login failed. Check your credentials!
                        </div>}
                        {authState === AuthStates.Authenticating && <div className="alert alert-info position-fixed bottom-0 mx-auto" role="alert">
                            Authenticating...
                        </div>}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LoginAdmin;