/* -------------------------------------------------------------------------- */
/*               THIS IS THE MAIN ENTRY POINT OF THE APPLICATION              */
/* -------------------------------------------------------------------------- */

// This file is the entry point of the application. It is the first file that is loaded when the application is started.
// It initializes the routes defined in Routes.tsx for the entire application and renders the root component.
// It also imports the index.css file which contains the global styles for the application and the bootstrap css file which contains the framework style.

import React, { useEffect } from 'react';
// React DOM is used to render the root component
import ReactDOM from 'react-dom/client';
// React Router is used to define the routes for the application
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
/* PROGRESS BAR CSS*/
import 'react-circular-progressbar/dist/styles.css';
// BOOTSTRAP CSS IMPORT - should ONLY be used in index.js, never in any other file
import 'bootstrap/dist/css/bootstrap.css';
//flickity css
import 'flickity/css/flickity.css';
// BOOTSTRAP VARIABLES CSS IMPORT - should ONLY be used in index.js, never in any other file
import './bootstrapVariables.css';
// INDEX CSS IMPORT - should ONLY be used in index.js, never in any other file
import './index.css';
// CUSTOM VARIABLES CSS IMPORT - should ONLY be used in index.js, never in any other file
import './customVariables.css';
// other imports
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-quill/dist/quill.snow.css';

import { FixedLoader } from 'components/Loader';
import { Toaster } from 'react-hot-toast';
import { AuthStates, useAuthStore } from './stores/authStore';
import { useFeedbackStore } from './stores/feedbackStore';
// import Swiper styles
import 'swiper/css';

import './i18n';

import { AmplitudeService } from 'services/amplitudeService';
import { useThemeStore } from 'stores/themeStore';
import { useAppStore } from 'stores/appStore';
import SystemModal from 'components/SystemModal';
import FeedbackModal from 'components/FeedbackModal';
import AppRoutes from 'Routes';
import ScrollToTop from 'components/ScrollToTop';
AmplitudeService.init();

// Build the root component
const root = ReactDOM.createRoot(document.getElementById('root')!);

/// Create the router for the application
const router = createBrowserRouter(
  createRoutesFromElements(
    (AppRoutes as React.ReactNode)
  )
);

// Render the root component
root.render(
  <AppInitializationWrapper>
    <Toaster position="top-right" />
    <RouterProvider router={router} />
    <SystemModal />
    <FeedbackModal />
  </AppInitializationWrapper>
);

/// The AppInitializationWrapper component is used to initialize the application.
/// It is used to initialize the authentication state of the application.
function AppInitializationWrapper(props: { children: React.ReactNode }) {

  useEffect(() => {
    const handleResize = () => {
      useAppStore.getState().checkMobile();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  // initialize the app
  const initAuthState = useAuthStore((state) => state.init);
  const authState = useAuthStore((state) => state.authenticationState);

  React.useEffect(() => {
    // Initialize the theme
    useThemeStore.getState().applyTheme();
  }, []);
  useEffect(() => {
    if (authState === AuthStates.Initial) {
      console.log('▶️ App Initialization: Initializing auth state');

      setTimeout(() => {
        initAuthState();
      }, 1000);
    }
    if (authState === AuthStates.Authenticated) {
      console.log('✅ App Initialization: Authenticated');
      useFeedbackStore.getState().checkPlatformFeedback();
    }
  }, [authState, initAuthState]);

  /*   React.useEffect(() => {
      setTimeout(() => {
        if (window.installPrompt && !shownInstallPrompt) {
          shownInstallPrompt = true;
          toast(<div>
            <p>📲 Funnifin è anche disponibile come app!<br></br>
              Installa Funnifin sul tuo device per usarla al meglio! </p>
            <Button className='w-100' onClick={() => {
              window.installPrompt.prompt();
              window.installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('User accepted the A2HS prompt');
                } else {
                  console.log('User dismissed the A2HS prompt');
                }
                window.installPrompt = null;
              }, 5000);
            }}>
              Provala!
            </Button>
  
          </div>);
        } else {
        }
      }, 2000);
    }); */

  return (
    <>
      {props.children}
      <ScrollToTop />
      <FixedLoader />
    </>
  )
}



// CURRENTLY NOT USED:
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/// reportWebVitals();

