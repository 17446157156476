import { motion } from "framer-motion";
import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import { AmplitudeService } from "services/amplitudeService";
import { useDocumentStore } from "stores/documentStore";
import { useI18nStore } from "stores/i18nStore";
import Config, { appThemes } from "utils/config";

function LearningMaterialsCard({ learningMaterial }: { learningMaterial: any }) {
    const getTranslation = useI18nStore(state => state.getTranslation);
    const { t } = useTranslation();
    const variantChildren = {
        "initial": {
            opacity: 0,
            scale: 0.4,
            y: 100,
        },
        "animate": {
            opacity: 1,
            scale: 1,
            y: 0,
        },
        "hover": {

        }
    };
    const slashMotion = {
        "hover": {
            scale: 1.07,
            transition: {
                type: "spring",
                bounce: 0.6,
            }
        }
    };
    return (
        <motion.div
            variants={variantChildren}
            className="d-flex align-items-stretch col h-100">
            <motion.div className="w-100 h-100" whileHover="hover">
                <div className="card w-100 h-100 cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        AmplitudeService.logEvent('Learning Material opened', { learningMaterial: learningMaterial.id, title: learningMaterial.multiLanguageTitle.it });
                        if (learningMaterial.storagePath) {
                            useDocumentStore.getState().downloadDocument(learningMaterial.id);
                        } else {
                            window.open(learningMaterial.url)
                        }
                    }}>
                    <div className="card-img-top"
                        style={{
                            clipPath: "border-box"
                        }}
                    >
                        <motion.img src={learningMaterial.img} alt=""
                            variants={slashMotion}
                            className="w-100"
                            style={{
                                height: "140px", minHeight: "140px", objectFit: "cover"
                            }} />
                    </div>
                    <div className="card-body h-100" >
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-outline-primary border-0 rounded-pill me-2"
                                style={{
                                    fontSize: "0.8rem",
                                    backgroundColor: "var(--bs-primary-subtle)",
                                    color: "var(--bs-primary)",
                                }}>
                                {t('learning_materials_categories:' + learningMaterial.category.replace(/\s+/g, ''))}
                            </button>
                        </div>
                        <h5 className="card-title mt-3 text-truncate-2">{getTranslation(learningMaterial.multiLanguageTitle)}</h5>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}
export default LearningMaterialsCard