import { create } from "./createStore";
import { AuthService } from "../services/authService";
import { useUserStore } from "./userStore";
import { useCompanyStore } from "./companyStore";
import { ApiError, ApiService, source } from "services/apiService";
import { AmplitudeService } from "services/amplitudeService";
import { t, use } from "i18next";

export const AuthStates = {
    Authenticated: Symbol("authenticated"),
    Authenticating: Symbol("authenticating"),
    AuthenticationFailed: Symbol("authenticationFailed"),
    AuthenticationExpired: Symbol("authenticationExpired"),
    LoggingOut: Symbol("loggingOut"),
    Unauthorized: Symbol("unauthorized"),
    Unauthenticated: Symbol("unauthenticated"),
    Unconfirmed: Symbol("unconfirmed"),
    ResetPasswordRequired: Symbol("resetPasswordRequired"),
    ChangePasswordRequired: Symbol("changePasswordRequired"),
    Initial: Symbol("initial"),
}

interface AuthStoreState {
    authenticationState: Symbol;
    authAccessToken: string | null;
    userSub: string | null;
    userStatus: string | null;
    userGroup: string | null;
    userAuthEmail: string | null;
    authRefreshToken: string | null;
    isAdmin: boolean;
    isSystem: boolean;
    isHR: boolean;
    isUser: boolean;
    init: () => Promise<void>;
    checkAuthState: () => Promise<Symbol | undefined>;
    refreshToken: () => Promise<any>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    registerAccount: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
    confirmAccount: (email: string, code: string) => Promise<boolean>;
    resendEmail: (email: string) => Promise<void>;
    requestPasswordReset: (email: string) => Promise<boolean>;
    confirmPasswordReset: (email: string, code: string, newPassword: string) => Promise<void>;
    newPassword: (user: string, newPassword: string) => Promise<void>;
    currentAuthUser: any;
    deleteAccount: () => Promise<void>;
    openSession: (userSub: string, accessToken: string, refreshToken: string/* , userGroup: string */) => Promise<Symbol>;
}


export const useAuthStore = create<AuthStoreState>()((set, get, __) => ({
    authenticationState: AuthStates.Initial,
    userStatus: null,
    authAccessToken: null,
    userSub: null,
    authRefreshToken: null,
    userGroup: null,
    isAdmin: false,
    isSystem: false,
    isHR: false,
    isUser: false,
    userAuthEmail: null,
    currentAuthUser: null,
    init: async () => {
        console.log('[AuthService] INIT STARTED');
        await AuthService.init();
        await useAuthStore.getState().checkAuthState();
        console.log('[AuthService] INIT COMPLETE');
    },
    /// Gets the current auth state
    checkAuthState: async () => {
        try {
            console.log('[AuthService]: Checking auth state');
            const userSub = localStorage.getItem('userSub');
            if (!userSub) {
                console.log('[AuthService] No session found');
                set({ authenticationState: AuthStates.Unauthenticated });
                return;
            }
            const session = await ApiService.auth.info(userSub);
            if (!session || !session.username || !session.userStatus) {
                set({ authenticationState: AuthStates.Unauthenticated });
                console.log('[AuthService]: Unauthenticated');
                return;
            }
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            return await get().openSession(session.username, accessToken ?? "", refreshToken ?? ""/* , session.userGroup */);
        } catch (e) {
            console.log('[AuthService]: Error', e);
            set({ authenticationState: AuthStates.Unauthenticated });
        }
    },
    /// refreshes the auth state
    refreshToken: async () => {
        try {
            set({ authenticationState: AuthStates.Authenticating });
            // Check auth provider session
            console.log('[AuthService]: Refreshing auth state');
            if (get().userSub === null) {
                // try to get the current userSub from local storage
                const userSub = localStorage.getItem('userSub');
                if (!userSub) {
                    console.log('[AuthService] No session found');
                    return null;
                }
                const session = await ApiService.auth.info(userSub);
                if (!session || !session.username) {
                    set({ authenticationState: AuthStates.Unauthenticated });
                    console.log('[AuthService]: Unauthenticated');
                    return;
                } else {
                    set({ userSub: session.username, userStatus: session.userStatus });
                }
            }
            console.log('[AuthService]: 2. Getting access token');
            const tokenResponse = await ApiService.auth.refreshToken(get().userSub!, get().authRefreshToken!);
            return tokenResponse ? tokenResponse! : null;
        } catch (e) {
            console.log('[AuthService]: Error', e);
            set({ authenticationState: AuthStates.Unauthenticated });
            return null;
            /* return AuthStates.Unauthenticated; */
        }
    },
    async openSession(userSub: string, accessToken: string, refreshToken: string, /* userGroup: string */) {
        try {
            set({ authAccessToken: accessToken, authRefreshToken: refreshToken, userSub: userSub });
            let userProfile = await useUserStore.getState().initUser(userSub);
            //TODO fix usergroup
            if (userProfile) {
                let userGroup = userProfile.userProfileDetail.group;
                set({
                    authenticationState: AuthStates.Authenticated,
                    isAdmin: userGroup === "Admin",
                    isSystem: userGroup === "System",
                    isHR: userGroup === "HR",
                    isUser: userGroup === "Users"
                });
                AmplitudeService.identify(userProfile);
                console.log(`[AuthService]: Role: ${userGroup}`);
                return AuthStates.Authenticated;
            } else {
                throw new Error('User not found');
            }
        } catch (e) {
            console.log('[AuthService]: Error', e);
            set({ authenticationState: AuthStates.Unauthenticated });
            return AuthStates.Unauthenticated;
        }
    },
    /// Logs in the user and sets the auth state to Authenticated
    /// Operates on user store to save the user data
    login: async (email, password) => {
        set({ authenticationState: AuthStates.Authenticating });

        try {
            console.log('[AuthStore] Login started');
            const response = await ApiService.auth.info(email);
            console.log('[AuthStore] Login response', response);
            const { userStatus, username,/*  userGroup */ } = response;
            set({ userSub: username, userStatus: userStatus, userAuthEmail: email /* userGroup: userGroup */ });
            // save to local storage
            localStorage.setItem('userSub', username);
            localStorage.setItem('userStatus', userStatus);
            /*             const loginResponse = await ApiService.auth.signIn(email, password);
                        console.log('[AuthService] Login successful', loginResponse); */
            switch (userStatus) {
                case 'FORCE_CHANGE_PASSWORD':
                case 'NEW_PASSWORD_REQUIRED':
                    set({ authenticationState: AuthStates.ResetPasswordRequired });
                    return;
                case 'DISABLED':
                case 'REGISTERED':
                    throw new Error(userStatus);
                case 'CONFIRMED':
                    break;
                case 'RESET_REQUIRED':
                    await get().resendEmail(email);
                    set({ authenticationState: AuthStates.ChangePasswordRequired });
                    return;
                default:
                    throw new Error('Unknown user status');
            }
        } catch (e) {
            alert('Unexpected error: ' + (e as Error).message);
            return;
        }
        try {
            const authUser = await ApiService.auth.signIn(email, password);
            set({ authAccessToken: authUser.accessToken, authRefreshToken: authUser.refreshToken });
            localStorage.setItem('accessToken', authUser.accessToken);
            localStorage.setItem('refreshToken', authUser.refreshToken);
            if (get().userSub !== null && get().authAccessToken !== null && get().authRefreshToken !== null) {
                get().openSession(get().userSub!, authUser.accessToken, authUser.refreshToken);
                return;
            }
        }
        catch (e) {
            set({ authenticationState: AuthStates.AuthenticationFailed });
            throw new Error("login_failed");
        }
    },
    logout: async () => {
        await set({ authenticationState: AuthStates.LoggingOut });
        setTimeout(async () => {
            await AuthService.logout();
            localStorage.removeItem('userSub');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            source.cancel('User logged out');
            setTimeout(async () => {
                set({ authenticationState: AuthStates.Unauthenticated, userSub: null, currentAuthUser: null });
            }, 500);
        }, 200);
    },
    registerAccount: async (email, password, firstName, lastName) => {
        await AuthService.registerAccount(email, password, firstName, lastName);
    },
    confirmAccount: async (email: string, code: string) => {
        return AuthService.confirmAccount(email, code).then(async () => {
            return true;
        }).catch((e) => {
            return false;
        });
    },
    resendEmail: async (email) => {
        set({ userAuthEmail: email });
        return await AuthService.resendEmail(email);
    },
    requestPasswordReset: async (email: string) => {
        return AuthService.requestPasswordReset(email).then(async () => {
            return true;
        }).catch((e) => {
            return false;
        });
    },
    confirmPasswordReset: async (email, code, newPassword) => {
        await AuthService.confirmPasswordReset(email, code, newPassword);
        set({ authenticationState: AuthStates.Unauthenticated });
    },
    newPassword: async (user, newPassword) => {
        await ApiService.auth.changePassword(user, newPassword);
        set({ authenticationState: AuthStates.Unauthenticated });
    },
    deleteAccount: async () => {
        await ApiService.users.delete(useUserStore.getState().user!.userProfileId);
        await AuthService.logout();
    }
}));

