import { toast } from 'react-hot-toast';
import { AuthStates } from 'stores/authStore';
import { resetAllStores } from 'stores/createStore';
import { AmplitudeService } from './amplitudeService';
import { ApiService } from "services/apiService";
import { AxiosError } from 'axios';
import { use } from 'i18next';

export const AuthService = {
    initCompleted: false,
    init: async () => {
        // Configurazione di Amplify rimossa poiché non più necessaria
        AuthService.initCompleted = true;
        return AuthStates.Authenticated;
    },
    login: async (email: string, password: string) => {

    },
    logout: async () => {
        console.log('[AuthService] Logout started');
        try {
            try {
                await ApiService.auth.logout();
            } catch (error) {
                console.log('[AuthService] Error during API logout', error);
            }
            AmplitudeService.reset();
            window.localStorage.removeItem('userSub');
            resetAllStores();
            return AuthStates.Unauthenticated;
        } catch (error) {
            console.log('[AuthService] Error during logout', error);
        }
        return null;
    },
    registerAccount: async (email: string, password: string, firstName: string, lastName: string) => {
        try {
            const response = await ApiService.auth.signup(email, password);
            return response;
        } catch (e) {
            console.log('[AuthService] Error during registration', e);
            throw e;
        }
    },
    confirmAccount: async (email: string, code: string) => {
        try {
            const response = await ApiService.auth.confirmUser(email, code);
            return response;
        } catch (e) {
            console.log('[AuthService] Error during confirmation', e);
            throw e;
        }
    },
    resendEmail: async (email: string) => {
        try {
            await AuthService.requestPasswordReset(email);
            /* TODO */
            /* const response = await ApiService.auth.resend(email); */
            /* return response; */
        } catch (e) {
            console.log('[AuthService] Error during resend', e);
            throw e;
        }
    },
    requestPasswordReset: async (email: string) => {
        try {
            const response = await ApiService.auth.resetPassword(email);
            return response;
        } catch (e) {
            console.log('[AuthService] Error during password reset request', e);
            throw e;
        }
    },
    confirmPasswordReset: async (email: string, code: string, newPassword: string) => {
        try {
            const response = await ApiService.auth.changePassword(email, newPassword, undefined, code);
            return response;
        } catch (e) {
            console.log('[AuthService] Error during password reset confirmation', e);
            throw e;
        }
    },
    newPassword: async (username: string, newPassword: string): Promise<any> => {
        try {
            const response = await ApiService.auth.changePassword(username, newPassword);
            return response;
        } catch (e) {
            console.log('[AuthService] Error during new password', e);
            throw e;
        }
    },
    refreshToken: async (userSub: string): Promise<any> => {
        try {
            // get refresh token from local storage
            const refreshToken = localStorage.getItem(userSub + '_refreshToken');
            const response = await ApiService.auth.refreshToken(userSub, refreshToken!);
            return response;
        } catch (e) {
            console.log('[AuthService] Error during refresh token', e);
            throw e;
        }
    }
};