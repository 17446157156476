import { AsyncChat, ChatMessage } from "models/chatModels";
import { UserProfile } from "models/userModels";
import { ApiService } from "services/apiService";
import { create } from "./createStore";
import { useAuthStore } from "./authStore";
import { useCompanyStore } from "./companyStore";
import { useUserStore } from "./userStore";
import cloneDeep from "clone-deep";
import i18n from "i18n";

export interface ChatStore {
    ready: boolean;
    activeChat: AsyncChat | null;
    chats: AsyncChat[];
    loading: boolean;
    unreadChats: string[];
    getChatData: (user: UserProfile) => Promise<void>;
    sendMessage: (message: ChatMessage, chat: AsyncChat) => Promise<void>;
    startChat: (message: ChatMessage) => Promise<void>;
    markAsRead: (chat: AsyncChat) => Promise<void>;
    closeChat: (chat: AsyncChat) => void;
    switchActiveChat: (chat?: AsyncChat) => void;
    restoreChat: (chat: AsyncChat) => void;
    refreshCurrentChat: () => Promise<void>;
    restoreAI: (chat: AsyncChat) => void;
}

export const useChatStore = create<ChatStore>()((set, get) => ({
    ready: false,
    activeChat: null,
    chats: [],
    unreadChats: [],
    loading: false,
    getChatData: async (user: UserProfile) => {
        return ApiService.asyncChat.search().then(async (chats) => {
            if (!get().loading) {
                set({
                    ready: true, chats: [...chats],
                    unreadChats: chats.filter((c: AsyncChat) => c.asyncChatDetail.isRead === false &&
                        ((
                            (useAuthStore.getState().isUser && c.asyncChatDetail.lastMessageType !== 'Users') ||
                            (!useAuthStore.getState().isUser && c.asyncChatDetail.lastMessageType === 'Users')
                        ) && c.asyncChatDetail.lastMessageType !== 'System')

                    ).map((c: AsyncChat) => c.asyncChatId)
                });
            }
        });
    },
    refreshCurrentChat: async () => {
        set({ loading: true });
        if (get().activeChat) {
            const chatId = get().activeChat?.asyncChatId;
            await ApiService.asyncChat.get(get().activeChat!.asyncChatId).then((chat: AsyncChat) => {
                if (get().activeChat && chatId === get().activeChat?.asyncChatId) {
                    set({
                        activeChat: chat,
                        unreadChats: get().chats.filter((c: AsyncChat) => c.asyncChatDetail.isRead === false &&
                            ((
                                (useAuthStore.getState().isUser && c.asyncChatDetail.lastMessageType !== 'Users') ||
                                (!useAuthStore.getState().isUser && c.asyncChatDetail.lastMessageType === 'Users')
                            ) && c.asyncChatDetail.lastMessageType !== 'System')).map((c: AsyncChat) => c.asyncChatId)
                    });
                }
            }
            );
        }
        set({ loading: false });
    },
    sendMessage: async (message: ChatMessage, chat: AsyncChat) => {
        set({ loading: true });
        const chatCopy = cloneDeep(chat);
        chatCopy.asyncChatDetail.messages.push(message);
        chatCopy.asyncChatDetail.lastMessageType = !useAuthStore.getState().isUser ? 'HR' : 'Users';
        chatCopy.asyncChatDetail.useAI = useAuthStore.getState().isUser ? chatCopy.asyncChatDetail.useAI : false;
        try {
            // update chat in chats array
            await ApiService.asyncChat.update(chatCopy);
            const chatIndex = get().chats.findIndex((c) => c.asyncChatId === chatCopy.asyncChatId);
            get().chats[chatIndex] = chatCopy;
            set({ activeChat: cloneDeep({ ...chatCopy }) });
            set({ chats: [...get().chats] });
        } catch (e) {
            console.log(e);
            throw new Error('Error sending message');
        }
        set({ loading: false });
    },
    startChat: async (message: ChatMessage) => {
        set({ loading: true });
        // will contain extra initial messages to be sent before the user message if present
        let initialMessages = [] as ChatMessage[];
        initialMessages.push(message);
        if (get().activeChat) {
            await get().closeChat(get().activeChat!);
        }

        await ApiService.asyncChat.create({
            companyId: useCompanyStore.getState().currentCompany?.companyId ?? '',
            userProfileId: useUserStore.getState().user?.userProfileId ?? '',
            title: initialMessages[0].message,
            description: 'Chat',
            isRead: false,
            lastMessageType: 'Users',
            type: 0,
            messages: initialMessages,
            useAI: true,
            isActive: true
        }).then((chat: AsyncChat) => {
            set({ activeChat: chat });
            set({ chats: [...get().chats, chat] });
        });
        set({ loading: false });
    },
    closeChat: (chat: AsyncChat) => {
        set({ loading: true });
        try {
            if (get().activeChat && get().activeChat?.asyncChatId === chat.asyncChatId) {
                set({ activeChat: null });
            }
            set({ chats: [...get().chats.filter((c) => c.asyncChatId !== chat.asyncChatId)] });
            ApiService.asyncChat.update(
                {
                    ...chat,
                    asyncChatDetail: {
                        ...chat.asyncChatDetail,
                        isActive: false,
                        isClosed: true,
                    }
                }
            );
        } catch (e) {
            console.log(e);
        }
        set({ loading: false });
    },
    // mark chat as read, does not update state
    markAsRead: async (chat: AsyncChat) => {
        chat.asyncChatDetail.isRead = true;
        try {
            ApiService.asyncChat.update(chat);
        } catch (e) {
            console.log(e);
            throw new Error('Error marking chat as read');
        }
    },
    switchActiveChat: async (chat?: AsyncChat) => {
        set({ loading: true });
        if (chat) {
            set({ activeChat: { ...chat } });
        } else {
            set({ activeChat: null });
        }
        set({ loading: false });
    },
    restoreChat: async (chat: AsyncChat) => {
        set({ loading: true });
        try {
            chat.asyncChatDetail.isClosed = false;
            chat.asyncChatDetail.useAI = true;
            chat.asyncChatDetail.status = "QUEUED";
            chat.asyncChatDetail.lastMessageType = 'Users';
            // delete last message
            chat.asyncChatDetail.messages.pop();
            ApiService.asyncChat.update(chat);
            set({ activeChat: { ...chat }, chats: [...get().chats] });
        } catch (e) {
            console.log(e);
        }
        set({ loading: false });
    },
    restoreAI: async (chat: AsyncChat) => {
        set({ loading: true });
        try {
            chat.asyncChatDetail.useAI = true;
            await ApiService.asyncChat.update(chat);
            console.log('chat restored');
            set({ activeChat: { ...chat }, chats: [...get().chats] });
        } catch (e) {
            console.log(e);
        }
        set({ loading: false });
    },
}));
